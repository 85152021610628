import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import helpers from '../../helpers';

import Breadcrumbs from '../breadcrumbs';
import ContentWrapper from '../content-wrapper';
import Site from './site';


class SiteList extends React.Component {
  static propTypes = {
    settings: PropTypes.object.isRequired,
  }

  render() {
    const { settings } = this.props;

    const sites = settings.sites.map((site) => helpers.ui.getSite(site.id));

    return (
      <ContentWrapper align="top">
        <Breadcrumbs />
        <Grid container spacing={2}>
          {sites.map((site) => (
            <Grid key={site.id} item xs={12} sm={6} md={4}>
              <Site site={site} />
            </Grid>
          ))}
        </Grid>
      </ContentWrapper>
    );
  }
}

export default SiteList;
