import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import actions from '../../actions';
import querystring from '../../utils/querystring';

import { useApiForm } from '../../hooks';
import SubmitButton from '../form/submit-button';
import TextField from '../form/text-field';


export default function ResetPasswordForm({ cms, onSuccess }) {
  const { fieldProps, submit, errMsg, loading, ...rest } = useApiForm({
    onSubmit: (data) => {
      // Validate
      if (data.password !== data.confirmPassword) {
        return Promise.reject(new Error(cms.text('errors.passwordMismatch')));
      }

      if (data.password.length < 7) {
        return Promise.reject(new Error(cms.text('errors.passwordLength')));
      }

      // Submit data with the token from the email link
      data.token = querystring.get('token');

      return actions.user.resetPassword(data);
    },
    onSuccess
  })
  return (
    <form onSubmit={submit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            {...fieldProps('email')}
            label={<cms.Text id="email" />}
            type="email"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...fieldProps('password')}
            label={<cms.Text id="password" />}
            type="password"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...fieldProps('confirmPassword')}
            label={<cms.Text id="confirmPassword" />}
            type="password"
            required
          />
        </Grid>
        <Grid item xs={12}>
          {errMsg
            && (
              <cms.Object id="errors" keys="passwordMismatch,passwordLength">
                <Typography color="error">{errMsg}</Typography>
              </cms.Object>
            )}
          <SubmitButton
            cms={cms}
            loading={loading}
          />
        </Grid>
      </Grid>
    </form>
  );
}

ResetPasswordForm.propTypes = {
  cms: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
