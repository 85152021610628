import React from 'react';
import { WurdText } from 'wurd-react';
import { useTheme } from '@material-ui/core';

const stateColors = {
  paid: 'success',
  draft: 'warning',
  failed: 'error',
  sent: 'info',
  pending: 'info',
};

export default function StateLabel({ state, style, ...props }) {
  const theme = useTheme();

  const color = theme.palette[stateColors[state]].main;

  return (
    <WurdText id={`invoiceDetails.${state}`} style={{ color, fontWeight: 500, ...style }} {...props} />
  );
}
