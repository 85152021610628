export default {
  get,
  set,
};


const storageKey = 'cmsContent';


function get() {
  try {
    const content = window.localStorage.getItem(storageKey);

    if (!content) return null;

    // console.log('Loaded cached CMS content');
    return JSON.parse(content);
  } catch (err) {
    console.error('Error loading CMS content from localStorage', err);

    return null;
  }
}


function set(content) {
  try {
    // console.log('Saving cached CMS content');

    window.localStorage.setItem(storageKey, JSON.stringify(content));
  } catch (err) {
    console.error('Error saving content to localStorage', err);
  }
}
