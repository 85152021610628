import React, { useEffect, useState } from 'react';
import {
  Route, BrowserRouter, Switch, Redirect,
} from 'react-router-dom';

import config from './config';
import actions from './actions';
import store from './store';

import Layout from './components/layout';
import LogIn from './components/login';
import ForgotPassword from './components/forgot-password';
import ResetPassword from './components/reset-password';
import SiteDetail from './components/site-detail';
import SiteList from './components/site-list';
import UnitDetail from './components/unit-detail';
import Profile from './components/profile';
import MyUnits from './components/my-units';
import NotFound from './components/not-found';
import Spinner from './components/spinner';
import BillingInfo from './components/billing-info';
import Invoices from './components/invoices';
import InvoiceDetails from './components/invoices/invoice-details';
import DynamicLoader from './components/dynamic-loader';

const SiteMap = DynamicLoader(
  React.lazy(() => import('./components/site-map')),
);
const DevUiPreview = DynamicLoader(
  React.lazy(() => import('./components/_dev/ui-preview')),
);

export default function Router() {
  return (
    <BrowserRouter basename={config.basePath}>
      <Switch>
        <PrivateRoute exact path="/profile" component={Profile} />
        <PrivateRoute exact path="/my-units" component={MyUnits} />
        <PrivateRoute exact path="/billing" component={BillingInfo} />
        <PrivateRoute exact path="/invoices" component={Invoices} />
        <PrivateRoute exact path="/invoices/:sid" component={InvoiceDetails} />

        <PublicRoute exact path="/" component={SiteList} />

        <PublicRoute exact path="/login" component={LogIn} />
        <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
        <PublicRoute exact path="/reset-password" component={ResetPassword} />

        <PublicRoute exact path="/site-map" component={SiteMap} />

        <PublicRoute exact path="/:siteId" component={SiteDetail} />
        <PublicRoute exact path="/:siteId/units/:unitTypeId" component={UnitDetail} />

        <PublicRoute exact path="/_dev/ui" component={DevUiPreview} />

        <PublicRoute component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

function PublicRoute({ component: Component, ...props }) {
  useEffect(() => {
    const { user } = store.get();

    if (!user && window.localStorage.getItem('accessToken')) {
      actions.user.fetch()
        .catch((err) => {
          if (err.status === 401) localStorage.removeItem('accessToken');
        });
    }
  }, []); // run on mount only

  const { settings, user } = store.get();

  return (
    <Route
      {...props}
      render={() => <Layout><Component settings={settings} user={user} /></Layout>}
    />
  );
}

function PrivateRoute({ component: Component, ...props }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const { user } = store.get();

    if (!user && window.localStorage.getItem('accessToken')) {
      setLoading(true);

      actions.user.fetch()
        .catch((err) => {
          if (err.status === 401) localStorage.removeItem('accessToken');
        })
        .then(() => { // it's like a .finally here since in .catch above we didn't re-throw
          setLoading(false);
        })
    } else {
      setLoading(false);
    }
  }, []); // run on mount only


  const { settings, user } = store.get();

  if (loading) return <Spinner />;

  if (!user) return <Redirect to="/login" />;

  return (
    <Route
      {...props}
      render={() => <Layout><Component settings={settings} user={user} /></Layout>}
    />
  )
}
