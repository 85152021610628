import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { WurdText } from 'wurd-react';
import helpers from '../../helpers';
import ItemCard from '../item-card';
import Tooltips from '../tooltips';

const wurdSection = 'siteDetail.unitType';

const stopPropagation = e => { e.stopPropagation(); };

function getSiteUnitTypes(site) {
  if (!site || !site.availability) return [];

  const unitTypes = Object.keys(site.availability).reduce((memo, unitTypeId) => {
    const availability = site.availability[unitTypeId];

    if (availability && availability.total > 0) {
      const unitType = helpers.ui.getUnitType(site, unitTypeId, true);
      if (!unitType) return memo;

      unitType._numAvailable = availability.available;

      memo.push(unitType);
    }

    return memo;
  }, []);

  // Sort by price
  unitTypes.sort((a, b) => {
    if (a.price > b.price) return 1;
    if (a.price < b.price) return -1;
    return 0;
  });

  return unitTypes;
}

export default function UnitsGrid({ site }) {
  return (
    <Grid container spacing={2}>
      {getSiteUnitTypes(site).map((unitType, index) => {
        const isAvailable = !!unitType._numAvailable;
        const unitUrl = `${site.code}/units/${unitType.code}`;

        const availableButton = (
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={unitUrl}
            onClick={stopPropagation}
          >
            <WurdText id={`${wurdSection}.book`} />
          </Button>
        );

        const soldOutButton = (
          <Button
            variant="contained"
            color="primary"
            disabled
          >
            <WurdText id={`${wurdSection}.soldOut`} />
          </Button>
        );

        return (
          <ItemCard
            key={unitType.id}
            image={unitType.image}
            title={unitType.title}
            subtitle={unitType.subtitle}
            price={unitType.price_display || unitType.price}
            disabled={!isAvailable}
            to={isAvailable ? unitUrl : null}
          >
            <Tooltips tags={unitType.tags} />
            {isAvailable ? availableButton : soldOutButton}
          </ItemCard>
        );
      })}
    </Grid>
  );
}

UnitsGrid.propTypes = {
  site: PropTypes.object.isRequired
};
