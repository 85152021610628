import React from 'react';
import config from '../../../config';
import TextBlock from '../../text-block';
import Markdown from '../../markdown';

import './index.scss';

function formatAddressForMap(address = '') {
  return address.replace(/\n/g, ',');
}


export default function SiteInfo({ site }) {
  if (!site.info) return null;

  const mapAddress = formatAddressForMap(site.address);

  return (
    <TextBlock className="sg-site-info">
      <div className="sg-column full-bleed sg-map">
        {/* <iframe title="map" src={`https://www.google.com/maps?q=${formatAddressForMap(site.address)}&output=embed`} width="100%" height="100%" frameBorder="0" style={{ border: 0, minHeight: 300 }} allowFullScreen /> */}
        <a href={`https://maps.google.com/?q=${mapAddress}`} rel="noopener noreferrer" target="_blank">
          <img
            src={`https://maps.googleapis.com/maps/api/staticmap?&size=600x600&scale=2&markers=${mapAddress}&key=${config.mapsApiKey}`}
            alt="Location map"
          />
        </a>
      </div>
      <div className="sg-column">
        <Markdown>{site.info}</Markdown>
      </div>
    </TextBlock>
  );
}
