import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import helpers from '../../helpers';

import './index.scss';
import { useHistory } from 'react-router-dom';

export default function ItemCard({
  image, title, subtitle, price, disabled, to, children, ...props
}) {
  const formattedPrice = helpers.ui.price(price);

  const cardStyles = disabled ? { filter: 'grayscale(100%)', opacity: 0.75 } : { filter: 'grayscale(0%)', opacity: 1 };

  const history = useHistory();

  const onClick = to ? () => { history.push(to); } : null;

  return (
    <Grid {...props} item xs={12} sm={6} className="sg-item-card" style={cardStyles} onClick={onClick}>
      <div className="sg-image-wrapper">
        <img src={image} alt={title} data-object-fit />
      </div>
      <div className="sg-copy-wrapper">
        <div className="sg-title">
          <MediaQuery maxWidth={400}>
            <Typography variant="body1" gutterBottom>
              <span className="ellipsis">{title}</span>
            </Typography>
            <Typography variant="body1" color="secondary" gutterBottom>
              <span className="ellipsis">{subtitle}</span>
            </Typography>
          </MediaQuery>
          <MediaQuery minWidth={401}>
            <Typography variant="subtitle1" gutterBottom>
              <span className="ellipsis">{title}</span>
            </Typography>
            <Typography variant="subtitle1" color="secondary" gutterBottom>
              <span className="ellipsis">{subtitle}</span>
            </Typography>
          </MediaQuery>
        </div>
        <div className="sg-price">
          <MediaQuery maxWidth={400}>
            <Typography variant="body1" gutterBottom>
              {formattedPrice}
            </Typography>
          </MediaQuery>
          <MediaQuery minWidth={401}>
            <Typography variant="subtitle1" gutterBottom>
              {formattedPrice}
            </Typography>
          </MediaQuery>
        </div>

        <div className="sg-button-wrapper">
          {children}
        </div>
      </div>
    </Grid>
  );
}

ItemCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.string.isRequired,
  price: PropTypes.node.isRequired,
  tags: PropTypes.array,
  button: PropTypes.node,
  disabled: PropTypes.bool,
  to: PropTypes.string, // if not null, link to this url
};
