import React from 'react';
import wurd from 'wurd-react';

import Paper from '@material-ui/core/Paper';

import ContentWrapper from '../content-wrapper';
import PageTitle from '../page-title';
import ProfileForm from './form';

class Profile extends React.Component {
  cms = wurd.block('profile');

  render() {
    const { cms } = this;

    const paperStyles = { padding: '24px' };

    return (
      <ContentWrapper form>
        <PageTitle background><cms.Text id="title" /></PageTitle>
        <Paper style={paperStyles}>
          <ProfileForm
            cms={cms.block('form')}
          />
        </Paper>
      </ContentWrapper>
    );
  }
}

export default Profile;
