import React from 'react';
import FileField from './file-field';
import DateField from '../../components/form/date-field';
import TextField from '../../components/form/text-field';

export default function CustomField({ type, enum: _enum, ...props }) {
  if (type === 'file') {
    return <FileField {...props} />;
  }

  if (type === 'date') {
    return <DateField minDate={null} {...props} />;
  }

  return (
    <TextField type={type} select={!!_enum} SelectProps={{ native: true }}  {...props}>
      {!!_enum && ['', ..._enum].map(v => <option key={v} value={v}>{v}</option>)}
    </TextField>
  );
}
