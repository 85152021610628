import React from 'react';
import wurd from 'wurd-react';
import PropTypes from 'prop-types';
import helpers from '../../helpers';

import SectionHeader from '../section-header';
import ContentWrapper from '../content-wrapper';
import NotFound from '../not-found';
import Breadcrumbs from '../breadcrumbs';
import UnitsGrid from './units-grid';
import SiteHero from './site-hero';
import SiteInfo from './site-info';
import { useParams } from 'react-router-dom';


const cms = wurd.block('siteDetail');

export default function SiteDetail({ settings, ...props }) {
  const { siteId } = useParams();

  const site = helpers.ui.getSite(siteId);
  if (!site) return <NotFound />;

  const childProps = {
    site,
    ...props,
  };

  return (
    <ContentWrapper align="top">
      <Breadcrumbs site={site} />
      <SiteHero {...childProps} />
      <SiteInfo {...childProps} />
      <SectionHeader title={<cms.Text id="unitList.header" />} />
      <UnitsGrid {...childProps} />
    </ContentWrapper>
  );
}

SiteDetail.propTypes = {
  settings: PropTypes.object.isRequired,
};
