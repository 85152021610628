import React from 'react';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';


const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useSnackbarStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: green[400],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function MySnackbarContent({ className, message, onClose, variant, ...other }) {
  const Icon = variantIcon[variant];
  const classes = useSnackbarStyles();

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={(
        <span className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      )}
      {...other}
    />
  );
}


const useStyles = makeStyles((theme) => ({
  margin: {
    marginBottom: theme.spacing(4),
    maxWidth: 'none',
  },
}));

export default function Alert({
  type = 'info', children: message, ...rest
}) {
  const classes = useStyles();

  return (
    <MySnackbarContent
      variant={type}
      className={classes.margin}
      message={message}
    />
  );
}
