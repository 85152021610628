import React from 'react';
import wurd from 'wurd-react';

import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ContentWrapper from '../content-wrapper';
import LoginForm from './form';
import PageTitle from '../page-title';


const cms = wurd.block('common.login');

export default function LogIn() {
  return (
    <ContentWrapper form>
      <PageTitle background><cms.Text id="title" /></PageTitle>
      <Paper style={{ padding: 24 }}>
        <LoginForm />
      </Paper>
      <div style={{ marginTop: 24 }}>
        <Link to="/forgot-password">
          <Typography color="secondary" style={{ textAlign: 'center' }}>
            <cms.Text id="forgotPassword" />
          </Typography>
        </Link>
      </div>
    </ContentWrapper>
  );
}
