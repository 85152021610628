import api from '../api';
import errHandler from './_errhandler';


export const fetchInvoices = () => api.get('/v1/invoices')
  .then((res) => {
    const invoices = res.data;

    return invoices;
  })
  .catch(errHandler);


export const fetchInvoice = (sid) => api.get(`/v1/invoices/${sid}`)
  .then(res => res.data)
  .catch(errHandler);
