import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import helpers from '../../helpers';
import actions from '../../actions';
import AccountStep from './account';
import ProductsStep from './products';
import SummaryStep from './summary';
import BillingStep from './billing';
import SuccessStep from './success';
import AgreementStep from './agreement';


const getNextStep = helpers.order.createStepManager([
  'account',
  'products',
  'summary',
  'billing',
  'agreement',
  'order',
  'success',
]);

class OrderFlow extends React.Component {
  static propTypes = {
    settings: PropTypes.object.isRequired,
    site: PropTypes.object.isRequired,
    unitType: PropTypes.object.isRequired,
    user: PropTypes.object, // may be null if not logged in user
  };

  state = {
    step: getNextStep({
      settings: this.props.settings,
      site: this.props.site,
    }),
    user: this.props.user,
    startDate: null,
    products: undefined,
    orderId: null,
    signatureUrl: null,
  }

  /* componentDidUpdate(prevProps) {
    const {settings, user} = this.props;

    if (user && !prevProps.user) {
      this.setState({
        step: getNextStep(settings, user, null)
      });
    }
  }; */

  render() {
    const { step } = this.state;

    return this[`renderStep_${step}`]();
  }

  renderStep_account() {
    const { cms, user } = this.props;

    return (
      <AccountStep
        onSuccess={this.onAccountSuccess}
        cms={cms.block('account')}
        user={user} // re-render that step when user is loaded
      />
    );
  }

  renderStep_products() {
    const { site, cms } = this.props;

    return (
      <ProductsStep
        products={site.products}
        onSuccess={this.onProductsSuccess}
        cms={cms.block('products')}
      />
    );
  }

  renderStep_summary() {
    const { site, unitType, cms } = this.props;
    const { startDate, products } = this.state;

    return (
      <SummaryStep
        site={site}
        unitType={unitType}
        startDate={startDate}
        products={products}
        onSuccess={this.onSummarySuccess}
        cms={cms.block('summary')}
      />
    );
  }

  renderStep_billing() {
    const { settings } = this.props;
    const { user } = this.state;

    return (
      <BillingStep
        billing={settings.billing}
        brand={settings.brand}
        user={user}
        onSuccess={this.onBillingSuccess}
        onError={(err) => window.alert(err.message)}
      />
    );
  }

  renderStep_agreement() {
    const { cms, site, unitType } = this.props;
    const { startDate } = this.state;

    return (
      <AgreementStep
        site={site}
        unitType={unitType}
        startDate={startDate}
        cms={cms.block('agreement')}
        onSuccess={this.onAgreementSuccess}
      />
    );
  }

  renderStep_order() {
    const { cms } = this.props;

    this.createOrder();

    return (
      <Grid container justify="center" style={{ textAlign: 'center' }} spacing={2}>
        <Grid item xs={11}>
          <Typography variant="subtitle1" gutterBottom>
            <cms.Text id="order.title" />
          </Typography>
        </Grid>
        <Grid item xs={11} >
          <CircularProgress style={{ color: '#ccc' }} />
        </Grid>
      </Grid>
    );
  }

  renderStep_success() {
    const { site, unitType, cms } = this.props;
    const { startDate, products, orderId } = this.state;

    return (
      <SuccessStep
        site={site}
        unitType={unitType}
        startDate={startDate}
        products={products}
        orderId={orderId}
        cms={cms}
      />
    );
  }

  onAccountSuccess = (result) => {
    const { user, startDate } = result;

    this.setState({ user, startDate });
    this.showNextStep();
  };

  onProductsSuccess = (result) => {
    const { products } = result;

    this.setState({ products });
    this.showNextStep();
  };

  onSummarySuccess = () => {
    this.showNextStep();
  };

  onBillingSuccess = () => {
    this.showNextStep();
  };

  onAgreementSuccess = (result) => {
    const { signatureUrl } = result;

    this.setState({ signatureUrl });
    this.showNextStep();
  };

  showNextStep = (stateData) => {
    const { settings, site } = this.props;
    const { step, user } = this.state;
    const nextStep = getNextStep({ settings, site, user, step });
    if (!nextStep) return;

    this.setState({ ...stateData, step: nextStep });
  };

  createOrder = () => {
    const { site, unitType } = this.props;
    const { startDate, products, signatureUrl } = this.state;

    const data = {
      siteId: site.id,
      unitTypeId: unitType.id,
      startDate,
      products,
      signatureUrl,
    };

    actions.orders.create(data)
      .then(job => {
        this.showNextStep({ orderId: job.id });
      });
  };
}

export default withTheme(OrderFlow);
