import * as order from './order';
import * as ui from './ui';
import * as user from './user';
import * as sites from './sites';

export default {
  order,
  ui,
  user,
  sites,
};
