import React from 'react';
import wurd from 'wurd-react';
import Paper from '@material-ui/core/Paper';

import helpers from '../../helpers';

import NotFound from '../not-found';
import Breadcrumbs from '../breadcrumbs';
import SectionHeader from '../section-header';
import UnitHero from './unit-hero';
import UnitInfo from './unit-info';
import OrderFlow from './order-flow';
import ContentWrapper from '../content-wrapper';
import { useParams } from 'react-router-dom';

const cms = wurd.block('unitDetail');

export default function UnitDetail(props) {
  const { siteId, unitTypeId } = useParams();

  const site = helpers.ui.getSite(siteId);
  if (!site) return <NotFound />;

  const unitType = helpers.ui.getUnitType(site, unitTypeId);
  if (!unitType) return <NotFound />;

  const childProps = {
    site,
    unitType,
    cms,
    ...props,
  };

  return (
    <ContentWrapper align="top">
      <Breadcrumbs site={site} unitType={unitType} />
      <UnitHero {...childProps} />
      <UnitInfo {...childProps} />
      <SectionHeader title={<cms.Text id="orderFlow.header" />} />
      <Paper>
        <OrderFlow {...childProps} />
      </Paper>
    </ContentWrapper>
  );
}
