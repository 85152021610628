import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import helpers from '../../helpers';

const { datePeriod, price } = helpers.ui;

function InvoiceEntries({ invoice }) {
  return invoice.entries.map(entry => (
    <Grid key={entry.id} item xs={12}>
      <Grid container direction="row" style={{ alignItems: 'center' }}>
        <Grid item xs={6} sm={6}>
          <Grid container>
            <Typography variant="body1" component="p" style={{ fontSize: 12, float: 'left' }}>
              {entry.desc}
            </Typography>
          </Grid>
          <Grid container>
            <Typography variant="body1" component="p" style={{ fontSize: 12, float: 'left', color: '#666666' }}>
              {datePeriod(entry.date, entry.endDate).join(' - ')}
            </Typography>
          </Grid>
        </Grid>
        <Grid style={{ paddingLeft: 5 }} item xs={2} sm={2}>
          <Typography variant="body1" component="p" style={{ fontSize: 13, float: 'right', color: '#666666' }}>
            {entry.qty}
            {entry.qty && ' x '}
            {price(entry.amount)}
          </Typography>
        </Grid>
        <Grid style={{ paddingLeft: 5 }} item xs={2} sm={2}>
          <Typography variant="body1" component="p" style={{ fontSize: 13, float: 'right', color: '#666666' }}>
            {entry.tax !== 0 ? price(entry.tax) : null}
          </Typography>
        </Grid>
        <Grid style={{ paddingLeft: 5 }} item xs={2} sm={2}>
          <Typography variant="body1" component="p" style={{ fontSize: 13, float: 'right' }}>
            {price(entry.total)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  ));
}


export default InvoiceEntries;
