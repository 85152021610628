import React from 'react';
import wurd from 'wurd-react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ContentWrapper from '../content-wrapper';
import PageTitle from '../page-title';
import Form from './form';


class ForgotPassword extends React.Component {
  cms = wurd.block('common.forgotPassword');

  state = {
    success: false,
  };

  render() {
    const { cms } = this;
    const { success } = this.state;

    return (
      <ContentWrapper form>
        <PageTitle background><cms.Text id="title" /></PageTitle>
        <Paper style={{ padding: 24 }}>
          {!success
            ? this.renderForm()
            : this.renderSuccess()}
        </Paper>
      </ContentWrapper>
    );
  }

  renderForm() {
    const { cms } = this;

    return (
      <Form
        cms={cms.block('form')}
        onSuccess={this.onSuccess}
      />
    );
  }

  renderSuccess() {
    const { cms } = this;

    return (
      <Typography>
        <cms.Text id="success" />
      </Typography>
    );
  }

  onSuccess = () => {
    this.setState({ success: true });
  }
}


export default ForgotPassword;
