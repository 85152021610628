import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import PlaceIcon from '@material-ui/icons/Place';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import LinkButton from '../link-button';
import Hero from '../hero';


class MyUnitsSiteHeader extends React.Component {
  static propTypes = {
    site: PropTypes.object.isRequired,
    cms: PropTypes.object.isRequired,
  };

  render() {
    const { site, cms, theme } = this.props;

    const onAddressClick = () => { window.open(`https://maps.google.com/?q=${site.address}`); };

    const onPhoneClick = () => { window.open(`tel:${site.phone}`); };

    const onEmailClick = () => { window.open(`mailto:${site.email}`); };

    const iconStyles = {
      fontSize: theme.typography.h6.fontSize,
      color: theme.palette.secondary.main,
      marginRight: 0,
    };

    return (
      <Hero
        title={site.title}
        image={site.image}
      >
        <Typography variant="h6">
          {site.title}
        </Typography>
        <List>
          <ListItem onClick={onAddressClick} disableGutters>
            <ListItemIcon>
              <PlaceIcon style={iconStyles} />
            </ListItemIcon>
            <ListItemText secondary={<u>{site.address}</u>} />
          </ListItem>
          <ListItem onClick={onPhoneClick} disableGutters>
            <ListItemIcon>
              <PhoneIcon style={iconStyles} />
            </ListItemIcon>
            <ListItemText secondary={<u>{site.phone}</u>} />
          </ListItem>
          <ListItem onClick={onEmailClick} disableGutters>
            <ListItemIcon>
              <EmailIcon style={iconStyles} />
            </ListItemIcon>
            <ListItemText secondary={<u>{site.email}</u>} />
          </ListItem>
        </List>
        <div className="sg-button-wrapper">
          <LinkButton to={`/${site.code}`}>
            <cms.Text id="bookBtn" />
          </LinkButton>
        </div>
      </Hero>
    );
  }
}

export default withTheme(MyUnitsSiteHeader);
