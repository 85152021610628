import 'objectFitPolyfill';
import './index.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import wurd from 'wurd-react';

import language from './utils/language';
import cmsCache from './utils/cms-cache';
import Router from './router';
import store from './store';
import actions from './actions';

function render() {
  ReactDOM.render(<Router />, document.getElementById('root'));
}

async function start() {
  const settings = await actions.settings.fetch();

  document.title = settings.brand && settings.brand.name;

  // Determine the user's preferred language
  language.init(settings.languages);
  store.set({ lang: language.get() });

  // Connect to CMS
  wurd.connect('sg-units', {
    editMode: 'querystring',
    lang: language.get(),
    rawContent: cmsCache.get(),
  });

  // Show UI with cached content if available
  render();

  // Re-render when content is updated
  wurd
    .load(
      'common,profile,siteList,siteDetail,unitDetail,billing,siteMap,myUnits,billingInfo,invoices,invoiceDetails',
    )
    .catch((err) => console.error(err))
    .then((cms) => {
      render();

      cmsCache.set(wurd.store.rawContent);
    });

  // Re-render when store data changes
  store.onChange(render);
}

start();
