import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Button, Typography } from '@material-ui/core';
import { useApiForm } from '../../../hooks';
import DateField from '../../form/date-field.js';


export default function LoggedInForm(props) {
  const { fieldProps, submit, errMsg, loading, ...rest } = useApiForm(props);
  const { cms } = props;

  return (
    <form onSubmit={submit}>
      <Grid container spacing={2} justify="space-between">
        <Grid item xs={12} sm={6}>
          <DateField
            {...fieldProps('startDate')}
            label={<cms.Text id="startDate.label" />}
            required
          />
        </Grid>
        <Grid item xs={12}>
          {errMsg && <Typography color="error">{errMsg}</Typography>}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ float: 'right' }}
            disabled={loading}
          >
            <cms.Object keys="submit,submitting">
              {loading ? cms.text('submitting') : cms.text('submit')}
            </cms.Object>
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

LoggedInForm.propTypes = {
  onSuccess: PropTypes.func.isRequired
};
