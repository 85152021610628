import axios from 'axios';
import config from './config';

// Add base URL
axios.interceptors.request.use(
  (req) => {
    if (req.url[0] === '/') {
      req.url = config.apiUrl + req.url;
    }

    return req;
  },
);

// Add Authorization header
axios.interceptors.request.use(
  (req) => {
    if (!req.headers.Authorization) {
      const token = localStorage.getItem('accessToken');

      if (token) {
        req.headers.Authorization = `Bearer ${token}`;
      }
    }

    return req;
  },
);

// Handle errors; redirect to login on Unauthorized
axios.interceptors.response.use(
  (res) => res,

  (res) => {
    const err = (res.response && res.response.data && res.response.data.error) || res;
    err.status = (res.response && res.response.status) || res.status;
    return Promise.reject(err);
  },
);

export default axios;
