import React from 'react';
import { Grid, Typography } from '@material-ui/core';

function InvoiceHeaders({ cms }) {
  return (
    <Grid item xs={12}>
      <Grid container direction="row" style={{ alignItems: 'center' }}>
        <Grid item xs={6} />
        <Grid style={{ paddingLeft: 5 }} item xs={2} sm={2}>
          <Typography variant="body1" component="p" style={{ fontSize: 13, float: 'right', color: '#666666' }}>
            <cms.Text id="priceHeader" />
          </Typography>
        </Grid>
        <Grid style={{ paddingLeft: 5 }} item xs={2} sm={2}>
          <Typography variant="body1" component="p" style={{ fontSize: 13, float: 'right' }}>
            <cms.Text id="taxHeader" />
          </Typography>
        </Grid>
        <Grid style={{ paddingLeft: 5 }} item xs={2} sm={2}>
          <Typography variant="body1" component="p" style={{ fontSize: 13, float: 'right' }}>
            <cms.Text id="totalHeader" />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}


export default InvoiceHeaders;
