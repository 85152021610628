import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';

import { WurdText } from 'wurd-react';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export default function Site({ site }) {
  const theme = useTheme();

  const stylesWrapper = {
    height: 0,
    paddingBottom: '56.25%',
    position: 'relative',
    cursor: 'pointer',
  };

  const stylesImage = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };

  const stylesGrid = {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2.5),
  };

  const stylesButton = { marginTop: theme.spacing(2) };

  return (
    <Paper>
      <div style={stylesWrapper}>
        <Link to={`/${site.code}`}>
          <img src={site.image} alt={site.title} style={stylesImage} data-object-fit />
        </Link>
      </div>
      <Grid container justify="center" style={stylesGrid}>
        <Grid item xs={11}>
          <Grid container>
            <Grid item xs={11}>
              <Typography variant="h6">
                <span className="ellipsis">{site.title}</span>
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Typography color="secondary" gutterBottom>
                <span className="ellipsis">{site.address}</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="flex-end">
                <Grid item>
                  <Link to={`/${site.code}`}>
                    <Button variant="contained" color="primary" style={stylesButton}>
                      <WurdText id="siteList.button" />
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

Site.propTypes = {
  site: PropTypes.object.isRequired,
};
