import React from 'react';
import wurd from 'wurd-react';
import Grid from '@material-ui/core/Grid';
import { withTheme } from '@material-ui/core/styles';

import store from '../../store';
import helpers from '../../helpers';

import ContentWrapper from '../content-wrapper';
import PageTitle from '../page-title';
import ItemCard from '../item-card';
import Tooltips from '../tooltips';
import NoResults from '../no-results';
import SiteHeader from './site-header';
import AccessCodeButton from './access-code-button';
import { Button } from '@material-ui/core';


class MyUnitsPage extends React.Component {
  cms = wurd.block('myUnits');

  render() {
    const user = store.get('user');
    const { units } = user;

    if (!units.length) return this.renderNoResults();

    return this.renderUnits(units);
  }

  renderNoResults() {
    const { cms } = this;

    return <NoResults cms={cms} />;
  }

  renderUnits(units) {
    const { cms } = this;
    const unitsBySite = this.groupUnitsBySite(units);
    const siteCms = cms.block('site');

    return (
      <ContentWrapper align="top">
        <PageTitle><cms.Text id="title" /></PageTitle>
        {unitsBySite.map((unitGroup) => (
          <div key={unitGroup.site.id}>
            <SiteHeader
              site={unitGroup.site}
              cms={siteCms}
            />
            <Grid container spacing={2} style={{ paddingBottom: '18px' }}>
              {unitGroup.units.map((unit) => {
                const { rental } = unit;
                const unitType = helpers.ui.getUnitType(unitGroup.site, unit.typeId);

                return (
                  <ItemCard
                    key={unit.id}
                    image={unitType.image}
                    title={<cms.Text id="unitName" vars={{ name: unit.name }} />}
                    subtitle={helpers.ui.unitSize(unit)}
                    price={rental.price}
                  >
                    <Tooltips tags={unitType.tags} />
                    {rental.agreementUrl && (
                      <Button variant="contained" color="primary" href={rental.agreementUrl} target="_blank">
                        <cms.Text id="agreementButton" />
                      </Button>
                    )}
                    {Object.keys(rental.accessCodes || {}).length > 0
                      ? <AccessCodeButton
                        cms={cms.block('accessCodes')}
                        accessCodes={rental.accessCodes}
                      />
                      : null}
                  </ItemCard>
                );
              })}
            </Grid>
          </div>
        ))}
      </ContentWrapper>
    );
  }

  groupUnitsBySite(units) {
    const unitsBySite = units.reduce((memo, unit) => {
      const { siteId } = unit;

      if (!memo[siteId]) {
        memo[siteId] = {
          site: helpers.ui.getSite(siteId),
          units: [unit],
        };
      } else {
        memo[siteId].units.push(unit);
      }

      return memo;
    }, {});

    return Object.values(unitsBySite);
  }
}


export default withTheme(MyUnitsPage);
