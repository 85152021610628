import React from 'react';

import './index.scss';

class Hero extends React.Component {
  render() {
    const { image, title, children } = this.props;

    return (
      <div className="sg-hero">
        <div className="sg-image-wrapper">
          <img src={image} alt={title} data-object-fit />
        </div>
        <div className="sg-copy-wrapper">
          {children}
        </div>
      </div>
    );
  }
}

export default Hero;
