import React from 'react';
import marked from 'marked';
import './index.scss';

marked.setOptions({
  gfm: true,
  breaks: true,
  // silent: true,
});


export default function Markdown({ children: content, ...rest }) {
  if (typeof content !== 'string') {
    console.warn('Markdown received non-string item', content);

    return null;
  }

  return (
    <div
      className="sg-operator-markdown"
      dangerouslySetInnerHTML={{ __html: marked(content) }}
      {...rest}
    />
  );
}
