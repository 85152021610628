import React from 'react';
import moment from 'moment';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './date-field.css';

import TextField from './text-field';
import store from '../../store';

class DateField extends React.Component {
  render() {
    const {
      id, name, value, label, include, onChange, ...rest
    } = this.props;

    if (!include) return null;

    const { settings } = store.get();

    return (
      <DatePicker
        customInput={(
          <TextField
            label={label}
            include
          />
        )}
        selected={value ? moment(value) : null}
        dateFormat={settings.dateFormats.short}
        onChange={this.onChange}
        minDate={moment()}
        {...rest}
      />
    );
  }

  onChange = (momentDate) => {
    const { onChange, name } = this.props;
    const value = momentDate?.format('YYYY-MM-DD') ?? null;

    onChange({
      target: {
        name,
        value,
      },
    });
  }
}

export default DateField;
