import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import helpers from '../../helpers';

export default function InvoicesMobile({ invoice, settings }) {
  const paidStyle = {
    color: '#60C060',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    float: 'right',
    padding: 8,
  };

  const unpaidStyle = {
    color: '#ff0000',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    float: 'right',
    padding: 8,
  };

  const gridStyles = {
    marginBottom: 30,
    borderRadius: 10,
    boxShadow: '1px 1px 6px #d3d3d3',
  };

  return (
    <Link style={{ textDecoration: 'none', color: '#333' }} to={`invoices/${invoice.sid}`}>
      <Grid key={invoice.sid} container style={gridStyles}>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="subtitle1" color="secondary" style={{ textTransform: 'uppercase', padding: 8 }}>
              {invoice.sid}
            </Typography>
          </Grid>

          <Grid item xs>
            <Typography variant="subtitle1" component="p" style={{ paddingRight: 8, paddingTop: 8, float: 'right' }}>
              {helpers.ui.dateShort(invoice.startDate)}
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h6" style={{ padding: 8 }}>
              {settings.currency}
              {invoice.total}
            </Typography>
          </Grid>

          <Grid item xs>
            <Typography variant="body1" style={invoice.state === 'paid' ? paidStyle : unpaidStyle}>
              {invoice.state}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Link>
  );
}
