import React, { useEffect } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';


import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import Favicon from 'react-favicon';
import store from '../store';
import Spinner from './spinner';
import AppHeader from './app-header';
import LanguageSwitcher from './language-switcher';

import { getLuminance, darken } from '@material-ui/core/styles/colorManipulator';


export default function Layout({ children }) {
  const { settings, user } = store.get();

  // useEffect(() => { // Similar to componentDidUpdate:
  //   window.scrollTo(0, 0);
  // });

  if (!settings) return <Spinner />;

  const { brand } = settings;

  const correctedBrandColor = brand.color && getLuminance(brand.color) > .7 ? darken(brand.color, .2) : brand.color;

  // FIX ME: Shadows array, WTF?
  const theme = createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 770,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    palette: {
      primary: { main: correctedBrandColor },
      secondary: { main: grey[500] },
      error: { main: red[500] },
    },
    typography: {
      fontFamily: "'Gothic A1', sans-serif",
      useNextVariants: true,
    },
    shadows: ['none', 'none', 'none', 'none', 'none', 'none', 'none', 'none', 'none', 'none', 'none', 'none', 'none', 'none', 'none', 'none', 'none', 'none', 'none', 'none', 'none', 'none', 'none', 'none', 'none'],
    shape: { borderRadius: 0 },
    overrides: {
      MuiAppBar: {
        positionFixed: { height: '64px' },
        colorPrimary: { backgroundColor: brand.color }
      },
      MuiToolbar: {
        root: { height: '64px' },
      },
      MuiButton: {
        root: {
          textTransform: 'none',
          minWidth: 'auto',
        },
        containedPrimary: {
          color: '#fff',
          fontWeight: 'bold',
          backgroundColor: brand.linkColor,
        },
        label: {
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        },
      },
      'Mui-disabled': {
        textTransform: 'none',
        padding: '14px 16px 12px',
        lineHeight: 1,
      },
      MuiTab: {
        label: { textTransform: 'capitalize' },
      },
      MuiChip: {
        root: {
          backgroundColor: grey[100],
          color: grey[600],
        },
        avatar: {
          backgroundColor: grey[100],
          paddingLeft: '3px',
          color: grey[600],
        },
        label: {
          paddingTop: '3px',
          paddingLeft: '4px',
        },
      },
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      <Favicon url={brand.icon} />
      <AppHeader settings={settings} user={user} />
      {children}
      <LanguageSwitcher />
    </MuiThemeProvider>
  );
}
