import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SummaryTable from './table';


const Summary = ({ onSuccess, cms, ...rest }) => (
  <Paper style={{ padding: '28px 24px 16px 24px' }} className="clearfix">
    <Typography variant="h5" gutterBottom style={{ marginBottom: 20 }}>
      <cms.Text id="title" />
    </Typography>

    <SummaryTable
      cms={cms}
      {...rest}
    />

    <Button variant="contained" color="primary" onClick={onSuccess} style={{ marginTop: 40, float: 'right' }}>
      <cms.Text id="nextButton" />
    </Button>
  </Paper>
);

Summary.propTypes = {
  site: PropTypes.object.isRequired,
  unitType: PropTypes.object.isRequired,
  startDate: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  cms: PropTypes.object.isRequired,
};

export default Summary;
