import React from 'react';
import PropTypes from 'prop-types';
import wurd from 'wurd-react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';


class BreadcrumbsMobile extends React.Component {
  static propTypes = {
    site: PropTypes.object,
    unitType: PropTypes.object,
  };

  cms = wurd.block('common.breadcrumbs');

  render() {
    const { site, unitType } = this.props;
    const { cms } = this;

    const titleStyles = {
      padding: '20px 0 20px',
      textAlign: 'center',
    };

    return (
      <Grid container justify="center">
        { !site && !unitType
          && (
          <Grid item xs={11} lg={9} xl={7}>
            <Typography variant="subtitle1" style={titleStyles}><cms.Text id="selectSite" /></Typography>
          </Grid>
          )}
      </Grid>
    );
  }
}


export default BreadcrumbsMobile;
