import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import helpers from '../../helpers';

const { datePeriod, price } = helpers.ui;

function InvoiceItems({ invoice }) {
  return invoice.items.map(item => (
    <Grid key={item.id} item xs={12}>
      <Grid container direction="row" style={{ alignItems: 'center' }}>
        <Grid item xs={6} sm={6}>
          <Grid container>
            <Typography variant="body1" component="p" style={{ fontSize: 12, float: 'left' }}>
              {item.desc}
            </Typography>
          </Grid>
          <Grid container>
            <Typography variant="body1" component="p" style={{ fontSize: 12, float: 'left', color: '#666666' }}>
              {datePeriod(item.startDate, item.endDate).join(' - ')}
            </Typography>
          </Grid>
        </Grid>
        <Grid style={{ paddingLeft: 5 }} item xs={2} sm={2}>
          <Typography variant="body1" component="p" style={{ fontSize: 13, float: 'right', color: '#666666' }}>
            {item.quantity}
            {' '}
            x
            {price(item.price)}
          </Typography>
        </Grid>
        <Grid style={{ paddingLeft: 5 }} item xs={2} sm={2}>
          <Typography variant="body1" component="p" style={{ fontSize: 13, float: 'right', color: '#666666' }}>
            {item.tax && item.tax !== 0 ? price(item.tax) : null}
          </Typography>
        </Grid>
        <Grid style={{ paddingLeft: 5 }} item xs={2} sm={2}>
          <Typography variant="body1" component="p" style={{ fontSize: 13, float: 'right' }}>
            {price(item.total)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  ));
}


export default InvoiceItems;
