import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import './index.scss';

export default function ModalWindow({ modalOpen, closeModal, title, children }) {
  return (
    <Dialog open={modalOpen} onClose={closeModal}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={closeModal}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
