import moment from 'moment';

import store from '../store';


const siteLangFields = [
  'title',
  'subtitle',
  'info',
  'address',
];

const unitTypeLangFields = [
  'title',
  'subtitle',
  'info',
];

const unitTagLangFields = [
  'title',
];


/**
 * Checks if the string is a full ID
 *
 * @param {String} string
 *
 * @return {Boolean}
 */
function isId(string) {
  return (/^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i).test(string);
}


export function getLangText(obj, lang = store.get('lang')) {
  if (!obj) return null;

  const defaultLang = store.get('settings')?.defaultLang;

  return obj[lang] || obj[defaultLang] || Object.values(obj).find(Boolean);
}

export function date(dateStr) {
  const settings = store.get('settings');
  const format = settings.dateFormats.long;

  return moment(dateStr).format(format);
}

export function dateShort(date) {
  const settings = store.get('settings');
  const format = settings.dateFormats.short;

  return moment(date).format(format);
}


export function price(amount) {
  const { currency = '', defaultLang = 'en' } = store.get('settings');

  // Some currencies such as Yen don't use decimals
  const fractionDigits = ['￥'].includes(currency) ? 0 : 2;

  const formattedAmount = new Intl.NumberFormat(defaultLang, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(amount);

  return currency + formattedAmount;
}


export function unitSize(unit) {
  const { width, length, measure } = unit;

  if (measure === 'ft') {
    return `${width}' x ${length}'`;
  }

  return `${width} x ${length} ${measure}`;
}


/**
 * Returns the 'translated' site for a given code
 *
 * @param {String} codeOrId
 * @param {Boolean} [translate]
 *
 * @return {Object|Null} site
 */
export function getSite(codeOrId, translate = true) {
  const { settings, lang } = store.get();
  const { sites } = settings;

  const site = isId(codeOrId)
    ? sites.find((s) => s.id === codeOrId)
    : sites.find((s) => s.code === codeOrId);

  if (!site) {
    console.warn(`Site not found for code/id ${codeOrId}`); // eslint-disable-line no-console
    return null;
  }

  if (translate) {
    return {
      ...site,
      ...Object.fromEntries(siteLangFields.map(key => [key, getLangText(site[key])])),
    };
  }

  return site;
}


/**
 * Returns the 'translated' unit type for a given code
 *
 * @param {Object} site
 * @param {String} codeOrId
 * @param {Boolean} [translate]
 *
 * @return {Object|Null} unitType
 */
export function getUnitType(site, codeOrId, translate = true) {
  const { lang } = store.get();
  const { unitTypes = [] } = site;

  const unitType = isId(codeOrId)
    ? unitTypes.find((ut) => ut.id === codeOrId)
    : unitTypes.find((ut) => ut.code === codeOrId);

  if (!unitType) {
    console.warn(`Unit type not found for code/id ${codeOrId}`); // eslint-disable-line no-console
    return null;
  }

  if (translate) {
    return {
      ...unitType,
      ...Object.fromEntries(unitTypeLangFields.map(key => [key, getLangText(unitType[key])])),
      tags: unitType.tags.map((tag) => ({
        ...tag,
        ...Object.fromEntries(unitTagLangFields.map(key => [key, getLangText(tag[key])])),
      })),
    };
  }

  return unitType;
}

export function datePeriod(startDate, endDate, format = 'short') {
  const { settings, lang = 'en' } = store.get();
  const momentFormat = settings.dateFormats[format];

  const startMoment = moment(startDate).locale(lang);
  const result = [startMoment.format(momentFormat)];

  if (endDate) {
    const endMoment = moment(endDate).locale(lang);

    if (endMoment.month() !== startMoment.month() && endMoment.date() === startMoment.date()) {
      endMoment.add(-1, 'days');
    }

    result.push(endMoment.format(momentFormat));
  }

  return result;
}
