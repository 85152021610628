import api from '../api';
import store from '../store';
import errHandler from './_errhandler';


export const fetch = function () {
  return api.get('/v1/settings?include=userCustomFields')
    .then((res) => {
      const settings = res.data;

      store.set({ settings });

      return settings;
    })
    .catch(errHandler);
};
