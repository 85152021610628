import React, { useRef, useEffect, useState } from 'react';
import { Paper, Button, Grid, Typography, makeStyles } from '@material-ui/core';
import SignaturePad from 'signature_pad';
import Spinner from '../../spinner';
import actions from '../../../actions';

const useStyles = makeStyles(() => ({
  container: {
    fontFamily: 'Gothic A1',
    fontSize: 16,
    lineHeight: '28px',

    '& b, strong': {
      fontWeight: 'bold',
    },

    '& h1, h2, h3': {
      fontWeight: 'bold',
      marginTop: '1.5em',
    },

    '& h4, h5, h6': {
      fontWeight: 500,
      marginTop: '1em',
      lineHeight: '1.2em',
    },

    '& h1': {
      fontSize: '2.5em',
    },

    '& h2': {
      fontSize: '2em',
    },

    '& h3': {
      fontSize: '1.5em',
    },

    '& h4': {
      fontSize: '1.2em',
    },

    '& h5': {
      fontSize: '1em',
    },

    '& h6': {
      fontSize: '1em',
    },

    '& p': {
      marginTop: 0,
      marginBottom: '1rem',
      lineHeight: 1.5,
    },

    '& u, ins ': {
      textDecoration: 'underline',
      cursor: 'initial',
    },

    '& s, del': {
      textDecoration: 'line-through',
    },

    '& i, em': {
      fontStyle: 'italic',
    },

    '& table > thead > tr > th:empty': {
      display: 'none'
    },

    '& a': { color: 'blue', textDecoration: 'underline' },
    '& ul': { marginBottom: 5, marginLeft: 5, listStyle: 'square' },
    '& table': { marginBottom: 5, borderCollapse: 'collapse', width: '100%' },
    '& td:nth-child(1)': { width: '35%' },
    '& td, th': { border: '1px solid #000a', padding: '4px 8px' },
    '& th:empty': { display: 'none' },
    '& th': { fontWeight: 'bold', backgroundColor: '#EEEEEE' }
  },

  signature: {
    position: 'relative',
    margin: '20px auto 0',
    width: 480,

    '@media only screen and (max-width: 768px)': {
      width: '100%',
    },

    '& > canvas': {
      touchAction: 'none',
      border: '1px solid #0003',

      '@media only screen and (max-width: 768px)': {
        width: '100%',
      },
    }
  }

}));

export default function AgreementStep({ site, unitType, startDate, onSuccess, cms }) {
  const [agreementUrl, setAgreementUrl] = useState();
  const canvasRef = useRef();
  const signaturePad = useRef();
  const [isEmpty, setEmpty] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    const canvas = canvasRef.current;

    signaturePad.current = new SignaturePad(canvas, {
      // It's Necessary to use an opaque color when saving image as JPEG;
      // this option can be omitted if only saving as PNG or SVG
      backgroundColor: 'rgb(255, 255, 255)'
    });

    signaturePad.current.onEnd = () => {
      setEmpty(signaturePad.current.isEmpty());
    };

    actions.orders.previewAgreement({ siteId: site.id, unitTypeId: unitType.id, startDate, signatureUrl: null, agreementType: 'html' })
      .then(res => setAgreementUrl(res.html));
  }, []);

  async function handleSuccess() {
    const signatureUrl = signaturePad.current.toDataURL();

    onSuccess({ signatureUrl });
  }

  function reset() {
    signaturePad.current.clear();
    setEmpty(true);
  }

  return (
    <Paper style={{ padding: '28px 24px 24px 24px' }}>
      <Typography variant="subtitle1" gutterBottom style={{ marginBottom: '3em' }}>
        <cms.Text id="title" />
      </Typography>

      <Grid container justify="center" alignItems="center" direction="column">
        <div style={{ gridColumn: 1, width: 'min(800px, 100%)' }}>
          {agreementUrl
            ? <div dangerouslySetInnerHTML={{ __html: agreementUrl }} className={classes.container} />
            : <Spinner style={{ position: 'static', width: 'auto', height: 200 }} />
          }
          <div className={classes.signature}>
            <canvas
              ref={canvasRef}
              width={480}
              height={200}
            />
            <button onClick={reset} style={{ position: 'absolute', bottom: 3, right: 3 }}>clear</button>
          </div>
        </div>

        <Button variant="contained" color="primary" disabled={isEmpty} onClick={handleSuccess} style={{ marginTop: 40, float: 'right' }}>
          <cms.Text id={isEmpty ? 'emptyText' : 'nextButton'} />
        </Button>
      </Grid>
    </Paper>
  );
}
