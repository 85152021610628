import React from 'react';
import { withTheme } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';


const FormTextField = React.forwardRef(({ include, style, theme, ...rest }, ref) => {
  if (!include) return null;

  const textStyles = {
    width: '100%',
    marginBottom: theme.spacing(3),
    ...style,
  };

  return (
    <TextField
      InputLabelProps={{ shrink: true }}
      style={textStyles}
      ref={ref}
      {...rest}
    />
  );
});


export default withTheme(FormTextField);
