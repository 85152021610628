import React from 'react';

import Typography from '@material-ui/core/Typography';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import PlaceIcon from '@material-ui/icons/Place';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Hero from '../hero';
import { useTheme } from '@material-ui/core';

export default function SiteHero({ site }) {
  const theme = useTheme();

  const onAddressClick = () => { window.open(`https://maps.google.com/?q=${site.address}`); };

  const onPhoneClick = () => { window.open(`tel:${site.phone}`); };

  const onEmailClick = () => { window.open(`mailto:${site.email}`); };

  const iconStyles = {
    fontSize: '1.235em',
    color: theme.palette.secondary.main,
    marginRight: 0,
  };

  return (
    <Hero
      title={site.title}
      image={site.image}
    >
      <Typography variant="h6">
        {site.title}
      </Typography>
      <List>
        <ListItem onClick={onAddressClick} disableGutters>
          <ListItemIcon>
            <PlaceIcon style={iconStyles} />
          </ListItemIcon>
          <ListItemText secondary={<u>{site.address}</u>} />
        </ListItem>
        <ListItem onClick={onPhoneClick} disableGutters>
          <ListItemIcon>
            <PhoneIcon color="secondary" style={iconStyles} />
          </ListItemIcon>
          <ListItemText secondary={<u>{site.phone}</u>} />
        </ListItem>
        <ListItem onClick={onEmailClick} disableGutters>
          <ListItemIcon>
            <EmailIcon color="secondary" style={iconStyles} />
          </ListItemIcon>
          <ListItemText secondary={<u>{site.email}</u>} />
        </ListItem>
      </List>
    </Hero>
  );
}
