import React from 'react';
import pick from 'lodash/pick';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withTheme } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';

import store from '../../store';
import actions from '../../actions';
import helpers from '../../helpers';

import { useApiForm } from '../../hooks';
import TextField from '../form/text-field';
import CustomField from '../../plugins/custom-fields/field';


function ProfileForm({ cms }) {
  const user = store.get('user');
  const { userCustomFields = [] } = store.get('settings');

  const fields = [
    'firstName', 'lastName', 'email', 'address', 'phone',
    ...userCustomFields.map(({ code }) => `customFields.${code}`)
  ];
  const initialValue = pick(user, fields);

  const { fieldProps, submit, errMsg, errors, loading, ...rest } = useApiForm({
    initialValue,
    onSubmit: (data) => {
      return actions.user.update(data);
    },
  });

  return (
    <form onSubmit={submit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fieldProps('firstName')}
            label={<cms.Text id="firstName" />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fieldProps('lastName')}
            label={<cms.Text id="lastName" />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fieldProps('email')}
            label={<cms.Text id="email" />}
            type="email"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fieldProps('phone')}
            label={<cms.Text id="phone" />}
            type="tel"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...fieldProps('address')}
            label={<cms.Text id="address" />}
            multiline
            required
          />
        </Grid>
        {userCustomFields.map(({ code, title, ownerAcl, ...rest }) => {
          if (!ownerAcl?.includes('update')) return null; // only show it in profile if it has 'update' permission

          return (
            <Grid key={code} item xs={12} sm={6}>
              <CustomField
                {...fieldProps(`customFields.${code}`)}
                label={helpers.ui.getLangText(title)}
                {...rest}
              />
            </Grid>
          );
        })}
        <Grid item xs={12}>
          {errMsg && (
            <Typography color="error">
              {errMsg}
              {errors &&
                <ul>
                  {Object.entries(errors).map(([key, msg]) => (
                    <li key={key}>{key}: {msg}</li>
                  ))}
                </ul>
              }
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ float: 'right' }}
          >
            {loading && <CircularProgress style={{ color: '#ccc', marginRight: 12 }} size={24} />}
            <cms.Object keys="submit,submitting" type="span" style={{ margin: '6px 0' }}>
              {cms.text(loading ? 'submitting' : 'submit')}
            </cms.Object>
          </Button>
        </Grid>
      </Grid>
    </form >
  );
}

export default withTheme(ProfileForm);
