import React, { useState } from 'react';
import { Paper, Button, Grid, Typography } from '@material-ui/core';
import ProductCounter from './product-counter';


export default function ProductsStep({ products: productsSettings, onSuccess, cms }) {
  const [products, setProducts] = useState({});

  const handleSuccess = () => onSuccess({ products });

  return (
    <Paper style={{ padding: '28px 24px 24px 24px' }}>
      <Typography variant="subtitle1" gutterBottom style={{ marginBottom: '3em' }}>
        <cms.Text id="title" />
      </Typography>

      <Grid container spacing={2}>
        {productsSettings.map(product => {
          const handleChange = e => setProducts(prods => ({ ...prods, [e.target.name]: e.target.value }));

          return (
            <Grid key={product.id} item xs={12} md={6}>
              <ProductCounter value={products[product.id]} onChange={handleChange} product={product} />
            </Grid>
          );
        })}
      </Grid>

      <Grid container justify="center">
        <Button variant="contained" color="primary" onClick={handleSuccess} style={{ marginTop: 40, float: 'right' }}>
          <cms.Text id="nextButton" />
        </Button>
      </Grid>
    </Paper>
  );
}
