import React from 'react';
import PropTypes from 'prop-types';
import wurd from 'wurd-react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import actions from '../../actions';

import { useApiForm } from '../../hooks';
import SubmitButton from '../form/submit-button';
import TextField from '../form/text-field';
import { useHistory } from 'react-router-dom';


export default function LoginForm() {
  const history = useHistory();
  const { fieldProps, submit, errMsg, loading, ...rest } = useApiForm({
    onSubmit: data => actions.user.login(data)
      .catch((err) => {
        // Show custom message for invalid login details (401)
        if (err.status === 401) {
          throw new Error(cms.text('loginErr'));
        } else {
          throw err;
        }
      }),
    onSuccess: () => {
      history.push('/my-units');
    }
  });

  const cms = wurd.block('common.loginForm');

  return (
    <form onSubmit={submit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            {...fieldProps('email')}
            label={<cms.Text id="email" />}
            type="email"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...fieldProps('password')}
            label={<cms.Text id="password" />}
            type="password"
            required
          />
        </Grid>
        <Grid item xs={12}>
          {errMsg
            && <Typography color="error">{errMsg}</Typography>}
          <SubmitButton
            cms={cms}
            loading={loading}
          />
        </Grid>
      </Grid>
    </form>
  );
}
