import React from 'react';

import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import TextBlock from '../../text-block';
import Markdown from '../../markdown';

import { price } from '../../../helpers/ui';

import './index.scss';

export default function UnitInfo({ unitType, cms, theme }) {
  if (!unitType.info) return null;

  const avatarStyles = {
    marginRight: '12px',
    marginBottom: '12px',
  };

  return (
    <TextBlock className="sg-unit-info">
      <div className="sg-column">
        <Typography variant="h6" gutterBottom>
          <cms.Text id="price" vars={{ amount: price(unitType.price_display || unitType.price) }} />
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          <cms.Text id="deposit" vars={{ amount: price(unitType.deposit_display || unitType.deposit) }} />
        </Typography>
        <br />
        <br />
        <Typography gutterBottom fontWeight={500}><cms.Text id="features" /></Typography>
        <br />
        {unitType.tags.map((tag) => (
          <Chip
            key={tag.id}
            label={tag.title}
            avatar={<Avatar style={{ background: 'none' }}><i className={`fa fa-fw ${tag.icon}`} /></Avatar>}
            style={avatarStyles}
          />
        ))}
      </div>
      <div className="sg-column">
        <Markdown>{unitType.info}</Markdown>
      </div>
    </TextBlock>
  );
}
