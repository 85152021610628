import React from 'react';

import Button from '@material-ui/core/Button';


const FormSubmitButton = ({ cms, loading, ...rest }) => (
  <Button
    type="submit"
    variant="contained"
    color="primary"
    style={{ float: 'right' }}
    disabled={loading}
    {...rest}
  >
    <cms.Object keys="submit,loading">
      {loading
        ? (
          <span>
            <i className="fa fa-spinner fa-spin" />
            {' '}
            {cms.text('loading')}
          </span>
        )
        : cms.text('submit')}
    </cms.Object>
  </Button>
);


export default FormSubmitButton;
