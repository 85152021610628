import React from 'react';
import wurd, { WurdText } from 'wurd-react';

import Paper from '@material-ui/core/Paper';
import ContentWrapper from '../content-wrapper';
import PageTitle from '../page-title';
import Alert from '../alert';
import LoginForm from '../login/form';
import Form from './form';


class ResetPassword extends React.Component {
  cms = wurd.block('common.resetPassword');

  state = {
    success: false,
  };

  render() {
    const { cms } = this;
    const { success } = this.state;

    return (
      <ContentWrapper form>
        <PageTitle background>
          {!success
            ? <cms.Text id="title" />
            : <WurdText id="common.login.title" />
          }
        </PageTitle>
        <Paper style={{ padding: 24 }}>
          {!success
            ? this.renderForm()
            : this.renderSuccess()}
        </Paper>
      </ContentWrapper>
    );
  }

  renderForm() {
    const { cms } = this;

    return (
      <Form
        cms={cms.block('form')}
        onSuccess={this.onSuccess}
      />
    );
  }

  renderSuccess() {
    const { cms } = this;

    return (
      <div>
        <Alert type="success">
          <cms.Text id="success" />
        </Alert>

        <LoginForm />
      </div>
    );
  }

  onSuccess = () => {
    this.setState({ success: true });
  }
}


export default ResetPassword;
