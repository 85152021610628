import React from 'react';
import PropTypes from 'prop-types';


import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { price, getLangText } from '../../../helpers/ui';


function Subtitle({ title }) {
  return (
    <Grid item xs={12}>
      <Typography variant="h6">{title}</Typography>
    </Grid>
  );
}

function Item({ title, value }) {
  const values = Array.isArray(value) ? value : [value];

  return (
    <>
      <Grid item xs={6}>
        <Typography variant="body1">{title}</Typography>
      </Grid>
      {values.map((value, i) => (
        <React.Fragment key={i}>
          {i > 0 && <Grid item xs={6} />}
          <Grid item xs={6}>
            <Typography variant="body1">{value}</Typography>
          </Grid>
        </React.Fragment>
      ))}
    </>
  );
}


export default function SummaryTable({
  site, unitType, cms,
  startDate = new Date(), products = {}, paid = false,
}) {
  const deposit = unitType.deposit_display || unitType.deposit || 0;
  const rent = unitType.price_display || unitType.price || 0;

  const productsCharges = Object.entries(products).map(([id, qty]) => {
    const prod = site.products.find(prod => prod.id === id);
    return { ...prod, qty, amount: qty * prod.price };
  });

  const allCharges = [...unitType.charges, ...productsCharges];

  const moveinCharges = allCharges.filter((charge) => charge.type === 'movein');
  const recurringCharges = allCharges.filter((charge) => charge.type === 'recurring');

  const chargesTotal = allCharges.reduce((memo, charge) => {
    memo += Number(charge.amount);

    return memo;
  }, 0);

  const invoiceTotal = deposit + rent + chargesTotal;

  return (
    <Grid container spacing={2}>
      <Item title={<cms.Text id="location" />} value={site.title} />
      <Item title={<cms.Text id="unitType" />} value={unitType.title} />
      <Item title={<cms.Text id="moveIn" />} value={startDate} />
      <Item title={<cms.Text id="products" />} value={productsCharges.map(({ title, qty }) => `${getLangText(title)}: ${qty}`)} />


      <Grid item xs={12}><Divider /></Grid>

      <Subtitle title={<cms.Text id="moveinCharges" />} />

      <Item title={<cms.Text id="securityDeposit" />} value={price(deposit)} />

      {moveinCharges.map((charge) => (
        <Item
          key={charge.id}
          title={`${getLangText(charge.title)}${charge.qty > 1 ? ` (x${charge.qty})` : ''}`}
          value={price(charge.amount)}
        />
      ))}

      <Grid item xs={12}><Divider /></Grid>

      <Subtitle titleId="recurringCharges" />

      <Item title={<cms.Text id="monthlyRent" />} value={price(rent)} />

      {recurringCharges.map((charge) => (
        <Item
          key={charge.id}
          title={`${getLangText(charge.title)}${charge.qty > 1 ? ` (x${charge.qty})` : ''}`}
          value={price(charge.amount)}
        />
      ))}

      <Grid item xs={12}><Divider /></Grid>

      <Item title={<cms.Text id={paid ? 'total_paid' : 'total'} style={{ fontWeight: 'bold' }} />} value={price(invoiceTotal)} />

    </Grid>
  );
}

SummaryTable.propTypes = {
  site: PropTypes.object.isRequired,
  unitType: PropTypes.object.isRequired,
  startDate: PropTypes.string.isRequired,
  products: PropTypes.object,
  cms: PropTypes.object.isRequired,
};
