import React from 'react';
import { makeStyles, Button, Typography, Input } from '@material-ui/core';
import Markdown from '../../markdown';
import { getLangText } from '../../../helpers/ui';

const useStyles = makeStyles({
  root: {
    '&:not(:first-child)': {
      borderTop: '1px solid #eee'
    }
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 350,
    margin: 'auto',
  },
  img: {
    width: 130,
    height: 100,
    background: 'no-repeat 50%',
    backgroundSize: 180,
  },
  info: {
    width: 160,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    filter: 'opacity(0.8)',
  },
  desc: {
    margin: '1em 2em 2em',
    textAlign: 'center',
  },
  numberControl: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  number: {
    width: 50,
    fontSize: 40,
    border: 'none',
    outline: 'none',
    color: 'rgba(0,0,0,.87)',
    textAlign: 'center',
    '-moz-appearance': 'textfield',

    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
  btn: {
    minWidth: 'auto',
    width: 36,
    height: 36,
  }
});

export default function ProductCounter({ value = 0, onChange, product }) {
  const classes = useStyles();

  const max = Math.min(product.maxQuantity, product.type === 'movein' && product.trackInventory ? product.inventory : Infinity);

  const setCount = (count) => onChange({ target: { name: product.id, value: Math.max(Math.min(count, max), 0) } });

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.img} style={{ backgroundImage: `url(${product.image})` }}></div>

        <div className={classes.info}>
          <Typography variant="h5" style={{ fontSize: '1.2em', marginTop: 8 }}>{getLangText(product.title)}</Typography>
          <Typography variant="subtitle2">{getLangText(product.displayPrice)}</Typography>
          <div className={classes.numberControl}>
            <Button
              type="button"
              variant="outlined"
              className={classes.btn}
              onClick={() => setCount(value - 1)}
              disabled={value === 0}
            >
              <i className="fa fa-minus"></i>
            </Button>
            <Input
              type="number" min="0" max={max} step="1"
              disableUnderline classes={{ input: classes.number }}
              value={value} onChange={e => setCount(+e.target.value)}
            />
            <Button
              type="button"
              variant="outlined"
              className={classes.btn}
              onClick={() => setCount(value + 1)}
              disabled={value === max}
            >
              <i className="fa fa-plus"></i>
            </Button>
          </div>
        </div>
      </div>

      <Typography component="div" variant="caption" classes={{ root: classes.desc }}>
        <Markdown>{getLangText(product.description)}</Markdown>
      </Typography>
    </div>
  )
}
