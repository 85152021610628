import React from 'react';
import wurd from 'wurd-react';

import { Paper, Button, Typography, withStyles } from '@material-ui/core';
import ContentWrapper from '../content-wrapper';
import PageTitle from '../page-title';
import Spinner from '../spinner';
import store from '../../store';

import getBillingModule from '../../plugins';


const cms = wurd.block('billingInfo');

const styles = {
  row: {
    border: '#d3d3d3 solid 1px',
    marginBottom: -1,

    '&:last-child': {
      marginBottom: 0,
    },
  },
};

class BillingInfo extends React.Component {
  state = {
    loading: true,
    cards: null,
    bankAccount: null, // only used for gmopg for now
    paymentType: null, // only used for gmopg for now
    cardsError: null,
    bankAccountError: null,
  };

  billingModule = null;

  componentDidMount() {
    const { settings } = store.get();

    getBillingModule(settings.billing)
      .then(billingModule => {
        this.billingModule = billingModule;

        this.fetchCards();
      });
  }

  fetchCards = () => {
    this.setState({ loading: true });

    const { fetchCards } = this.billingModule;

    fetchCards()
      .then(({ cards, bankAccount, paymentType, cardsError, bankAccountError }) => {
        this.setState({
          cards, bankAccount, paymentType, cardsError, bankAccountError, loading: false,
        });
      })
      .catch(() => {
        this.setState({ cards: [], cardsError: null, bankAccountError: null, loading: false }); // if it fails (network error because api errors are in cardsError, bankAccountError), show empty list
      });
  }

  render() {
    const { settings } = store.get();

    const { loading, paymentType } = this.state;

    if (loading) return <Spinner />;

    const { BankAccount, BankAccountForm } = this.billingModule;

    return (
      <ContentWrapper form>
        <PageTitle background>
          <cms.Text id="title" />
        </PageTitle>
        <Paper style={{ padding: '32px 32px 160px' }}>

          {settings.billing === 'gmopg' && (
            <section style={{ marginBottom: '3em' }}>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <cms.Text id="paymentType.title" style={{ marginRight: 12 }} type={Typography} />

                <cms.Object id="paymentType" keys="creditCard,directDebit,null" type={Typography} variant="subtitle1" style={{ fontWeight: 500 }}>
                  {cms.text(`paymentType.${paymentType || null}`)}
                </cms.Object>
              </div>

              <cms.Text id="paymentType.infos" type={Typography} variant="caption" style={{ marginTop: '1em', marginLeft: '1em', paddingLeft: 4, borderLeft: '#d3d3d3 1px solid', whiteSpace: 'pre-wrap' }} />
            </section>
          )}

          <section>
            <Typography variant="h6">
              <cms.Text id="cards.title" />
            </Typography>

            {this.renderCards()}
          </section>

          {BankAccount && BankAccountForm && (
            <section style={{ marginTop: '4em' }}>
              <Typography variant="h6">
                <cms.Text id="bankAccount.title" />
              </Typography>

              {this.renderBankAccount()}
            </section>
          )}
        </Paper>
      </ContentWrapper >
    );
  }

  renderCards() {
    const { cards, cardsError } = this.state;
    const { settings, user } = store.get();

    const { Card, Form: CardForm } = this.billingModule;

    return (
      <div style={{ position: 'relative' }}>
        {cards.length === 0
          ? (
            <>
              <cms.Text id="noCards" type={Typography} variant="body1" style={{ padding: 10 }} />
              {cardsError && <Typography color="error">{cardsError}</Typography>}
            </>
          ) : (
            cards.map((card, i) => (
              <Card
                key={card.id || i}
                card={card}
                className={this.props.classes.row}
              />
            ))
          )}

        <CardForm
          brand={settings.brand}
          user={user}
          onSuccess={this.fetchCards}
          onError={(err) => window.alert(err.message)}
        >
          <Button variant="contained" color="primary" style={{ float: 'right', marginTop: 6 }}>
            <cms.Text
              id={
                cards && cards.length > 0
                  ? 'changeCardBtn'
                  : 'setCardBtn'
              }
            />
          </Button>
        </CardForm>
      </div>
    );
  }


  renderBankAccount() {
    const { bankAccount, bankAccountError } = this.state;
    const { settings, user } = store.get();

    const { BankAccount, BankAccountForm } = this.billingModule;

    return (
      <>
        {!bankAccount
          ? (
            <>
              <cms.Text id="noBankAccounts" type={Typography} variant="body1" style={{ padding: 10 }} />
              {bankAccountError && <Typography color="error">{bankAccountError}</Typography>}
            </>
          ) : (
            <BankAccount bankAccount={bankAccount} className={this.props.classes.row} />
          )}

        {!bankAccount && (
          <BankAccountForm
            brand={settings.brand}
            user={user}
            onSuccess={this.fetchCards}
            onError={(err) => window.alert(err.message)}
          >
            <Button variant="contained" color="primary" style={{ float: 'right', marginTop: 6 }}>
              <cms.Text id="setBankAccount" />
            </Button>
          </BankAccountForm>
        )}
      </>
    );
  }
}

export default withStyles(styles)(BillingInfo);
