import React from 'react';
import MediaQuery from 'react-responsive';
import BreadcrumbsMobile from './breadcrumbs-mobile';
import BreadcrumbsDesktop from './breadcrumbs-desktop';

class Breadcrumbs extends React.Component {
  render() {
    return (
      <div>
        <MediaQuery maxWidth={768}>
          <BreadcrumbsMobile {...this.props} />
        </MediaQuery>
        <MediaQuery minWidth={769}>
          <BreadcrumbsDesktop {...this.props} />
        </MediaQuery>
      </div>
    );
  }
}

export default Breadcrumbs;
