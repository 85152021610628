import React from 'react';
import wurd from 'wurd-react';
import TextField from '../../components/form/text-field';
import { InputAdornment } from '@material-ui/core';


const MAX_DOCS_SIZE = 10_000_000; // 10MB

const cms = wurd.block('common.plugins.customFields');

const isImage = name => /\.(gif|jpe?g|tiff|png|webp|bmp|svg)$/i.test(name);

function FileItem({ value }) {
  if (value) {
    return (
      <a
        href={value.url}
        target="_blank"
        rel="noreferrer noopener"
        style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {isImage(value.name) && (
          <img
            src={value.url}
            alt={value.name}
            loading="lazy"
            style={{ display: 'inline-block', verticalAlign: 'middle', objectFit: 'cover', width: 40, height: 40, marginRight: 8 }}
          />
        )}
        <span>{value.name || <cms.Text id="unnamedFile" />}</span>
      </a>
    );
  }

  return <cms.Text id="noValue" />;
}

const detailStyles = {
  flex: 1,
  display: 'inline-flex',
  alignItems: 'center',
  maxWidth: 'min(100%, 500px)',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

export default function FileField({ name, value, onChange, required, readOnly, ...props }) {
  function handleChange(evt) {
    const file = evt.target.files[0];

    if (!isImage(file.name) && file.size > MAX_DOCS_SIZE) {
      window.alert(cms.text('docSizeTooLarge', {
        size: new Intl.NumberFormat('en', { notation: 'compact' }).format(file.size),
        limit: new Intl.NumberFormat('en', { notation: 'compact' }).format(MAX_DOCS_SIZE),
      }));
      return;
    }

    const blobUrl = URL.createObjectURL(file);
    return onChange({
      target: {
        name,
        value: { url: blobUrl, name: file.name, file }
      }
    });
  }
  function handleDelete() {
    if (!value.url.startsWith('blob:') && !window.confirm(cms.text('confirmDeleteFile'))) return;

    return onChange({
      target: {
        name,
        value: ''
      }
    });
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <TextField
        type="file"
        key={value?.url}
        filename={value?.name}
        name={name}
        id={`sg-cf-${name}`}
        readOnly={readOnly}
        required={value?.url ? null : required}
        onChange={handleChange}
        inputProps={{
          style: { opacity: 0, width: 30 },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment style={{ width: '100%' }}>
              <cms.Object type="span" style={detailStyles} keys="confirmDeleteFile,docSizeTooLarge">
                <FileItem value={value} />
                {value && !required && !readOnly && (
                  <button type="button" onClick={handleDelete} style={{ background: 'none', border: 'none', width: 30, height: 30 }}>
                    <i className="fa fa-times" />
                  </button>
                )}
              </cms.Object>

              <label htmlFor={`sg-cf-${name}`}>
                <span><i className="fa fa-plus" /></span>
              </label>
            </InputAdornment>
          )
        }}
        {...props}
      />
    </div>
  )
}
