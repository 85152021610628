/**
 * A super simple store for global data
 * such as user info etc.
 */

const storeData = {};
const onChangeListeners = [];


function get(key) {
  if (key) {
    return storeData[key];
  }
  return storeData;
}


function set(newData) {
  Object.assign(storeData, newData);

  // if (Object.keys(newData).every(k => newData[k] === storeData[k])) return; // no change, skip change triggers

  onChangeListeners.forEach((fn) => fn.call(undefined, get()));
}


function onChange(fn) {
  onChangeListeners.push(fn);
}

export default {
  get,
  set,
  onChange,
};
