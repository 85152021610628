import React from 'react';
import { withTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const SectionHeader = ({ title, theme }) => {
  const headerStyles = {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2.5),
    fontWeight: 300,
  };

  return (
    <Grid container justify="center">
      <Grid item xs>
        <Typography style={headerStyles} variant="h6" align="center" color="secondary" gutterBottom>
          <span className="ellipsis">{title}</span>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withTheme(SectionHeader);
