import React from 'react';
import Typography from '@material-ui/core/Typography';
import './index.scss';

const PageTitle = ({ children, color, background }) => (
  <div className="sg-page-title" style={background ? { background: 'white' } : { background: 'transparent' }}>
    <Typography variant="h6" color={color}>{children}</Typography>
  </div>
);

export default PageTitle;
