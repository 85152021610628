import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Typography, Grid, withTheme, CircularProgress,
} from '@material-ui/core';
import { WurdText } from 'wurd-react';

import getBillingModule from '../../../plugins';


const wurdSection = 'billing.stripe'; // todo rename to "billing" and update wurd

function Billing({ billing, brand, user, theme, onSuccess, onError }) {
  const [billingModule, setBillingModule] = useState();
  useEffect(() => {
    getBillingModule(billing).then(setBillingModule);
  }, [billing]);

  const BillingForm = billingModule?.Form;

  return (
    <Grid
      container
      justify="center"
      style={{
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
      }}
    >
      <Grid item xs={11}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="subtitle1" style={{ marginBottom: theme.spacing() }} gutterBottom>
              <WurdText id="unitDetail.billing.title" />
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ position: 'relative', minHeight: 180 }}>
            {BillingForm
              ? (
                <BillingForm
                  brand={brand}
                  user={user}
                  onSuccess={onSuccess}
                  onError={onError}
                >
                  <Button variant="contained" color="primary">
                    <WurdText id={`${wurdSection}.setCardBtn`} />
                  </Button>
                </BillingForm>
              ) : (
                <CircularProgress style={{ color: '#ccc', marginLeft: 24 }} />
              )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

Billing.propTypes = {
  billing: PropTypes.string.isRequired,
  brand: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func,
};

export default withTheme(Billing);
