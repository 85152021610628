import React from 'react';

import actions from '../../../actions';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinkButton from '../../link-button';
import SummaryTable from '../summary/table';


export default function SuccessStep({ cms, orderId, site, ...rest }) {
  function showAgreement() {
    actions.orders.fetch(orderId)
      .then(job => {
        window.open(job.data.agreementUrl);
      });
  }

  return (
    <Paper style={{ padding: '28px 24px 16px 24px' }} className="clearfix">
      <Typography variant="h5" gutterBottom>
        <cms.Text id="confirmation.title" />
      </Typography>

      <Typography variant="body1" component="div" gutterBottom>
        <cms.Markdown id="confirmation.message" />
      </Typography>

      <Typography variant="h5" gutterBottom style={{ marginTop: 40 }}>
        <cms.Text id="confirmation.summaryTitle" />
      </Typography>

      <SummaryTable
        cms={cms.block('summary')}
        paid
        site={site}
        {...rest}
      />

      <LinkButton to="/my-units" style={{ marginTop: 40, float: 'right' }}>
        <cms.Text id="myUnits" />
      </LinkButton>
      {site.enableMoveInAgreement && orderId && (
        <Button variant="contained" color="primary" onClick={showAgreement} style={{ marginTop: 40, marginRight: 10, float: 'right' }}>
          <cms.Text id="myAgreement" />
        </Button>
      )}
    </Paper>
  );
}
