import store from '../store';


/**
 * @param {Object} settings
 * @param {Object} user
 *
 * @return {Boolean}
 */
export const hasBillingMethod = function (defaultSettings, user) {
  const billingMethod = defaultSettings.billing;

  const userBillingMethod = user && user.billingMethod;

  // If auto billing not supported by the customer then OK, all done
  if (!billingMethod) {
    return true;
  }

  // If this particular user has 'invoice' then they don't require the billing setup
  if (userBillingMethod === 'invoice') {
    return true;
  }

  // Otherwise billing method must match the defined billing method

  return (userBillingMethod === billingMethod);
};
