import React from 'react';
import wurd from 'wurd-react';
import { Link, useHistory } from 'react-router-dom';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import MapIcon from '@material-ui/icons/Map';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListIcon from '@material-ui/icons/ViewComfy';

import helpers from '../../helpers';


const cms = wurd.block('common.appBar');

export default function AppBar({ settings, toggleDrawer }) {
  const history = useHistory();
  const { brand } = settings;

  const { pathname } = history.location;

  const mappedSites = helpers.sites.listMapped();

  const iconStyles = {
    color: brand.textColor,
  };

  const showMapButton = pathname === '/' && mappedSites.length > 1;
  const showListButton = pathname === '/site-map';

  return (
    <MuiAppBar position="fixed">
      <Toolbar style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
      >
        <a href={settings.siteUrl} target="_blank" rel="noopener noreferrer">
          <img style={{ maxWidth: 135 }} src={brand.logo} alt={brand.name} />
        </a>
        <Grid container justify="flex-end">
          <cms.Object type={Grid} item keys="viewAsMap,viewAsList">
            {showMapButton
              && (
                <IconButton component={Link} to="/site-map" title={cms.text('viewAsMap')}>
                  <MapIcon style={iconStyles} />
                </IconButton>
              )}
            {showListButton
              && (
                <IconButton component={Link} to="/" title={cms.text('viewAsList')}>
                  <ListIcon style={iconStyles} />
                </IconButton>
              )}
          </cms.Object>
          <Grid item>
            <IconButton onClick={toggleDrawer}>
              <MenuIcon style={iconStyles} />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    </MuiAppBar>
  );
}
