import React from 'react';
import { Grid, Paper, Typography, Breadcrumbs, useTheme } from '@material-ui/core';
import wurd from 'wurd-react';
import { Link, useParams } from 'react-router-dom';

import ContentWrapper from '../content-wrapper';
import Spinner from '../spinner';
import helpers from '../../helpers';
import InvoiceItems from './invoice-items';
import InvoiceEntries from './invoice-entries';
import InvoiceHeaders from './headers';
import PageTitle from '../page-title';
import StateLabel from './state-label';
import NoResults from '../no-results';

import actions from '../../actions';

const cms = wurd.block('invoiceDetails');
const { dateShort, price } = helpers.ui;

export default function InvoiceDetails() {
  const params = useParams();
  const [invoice, setInvoice] = React.useState(null);
  const [error, setError] = React.useState(false);
  const theme = useTheme();

  React.useEffect(() => {
    actions.invoices.fetchInvoice(params.sid)
      .then(setInvoice)
      .catch(setError);
  }, [params.id]);

  if (!invoice) return error ? <NoResults cms={cms} /> : <Spinner />;

  return (
    <ContentWrapper>
      <PageTitle>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator="›"
          style={{ fontSize: 'inherit', fontWeight: 'inherit', display: 'inline-flex' }}
        >
          <Link to="/invoices" style={{ color: theme.palette.text.primary }}>
            <cms.Text id="invoicesList" />
          </Link>
          <Link to={`/invoices/${invoice.sid}`} style={{ color: theme.palette.text.primary }}>
            {invoice.sid.toUpperCase()}
          </Link>
        </Breadcrumbs>
      </PageTitle>
      <Grid container justify="center">
        <Grid item sm={8} xs={12}>
          <Paper style={{ padding: 15 }}>
            <Grid container spacing={2} style={{ justifyContent: 'center' }}>
              <Grid item sm={6} xs={6}>
                <Typography style={{ float: 'left', textTransform: 'uppercase', color: '#666666' }} variant="h6">
                  <strong style={{ fontWeight: 'bold', color: '#000' }}><cms.Text id="invoice" /></strong>
                  <span style={{ filter: 'opacity(.75)', fontWeight: 300, marginLeft: 8, marginRight: 1 }}>#</span>
                  <span>{invoice.sid}</span>
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Typography variant="body2" style={{ float: 'right', fontSize: 13, color: '#666666' }}>
                  <cms.Text id="date" />
                  {' '}
                  {dateShort(invoice.startDate)}
                </Typography>
              </Grid>
              {invoice.state !== 'sent' && (
                <Grid item xs={12}>
                  <Typography variant="h6">
                    <StateLabel state={invoice.state} style={{ fontWeight: 700 }} />
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="subtitle2" component="h6" style={{ color: theme.palette.info.dark }}>
                  <cms.Text id="yourOrder" />
                </Typography>
              </Grid>

              <InvoiceHeaders cms={cms} />

              {invoice.items && (
                <InvoiceItems invoice={invoice} />
              )}

              {invoice.entries && (
                <InvoiceEntries invoice={invoice} />
              )}

              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item xs={12} sm={12} style={{ float: 'right' }}>
                    <Typography style={{ float: 'right', fontSize: 13 }} component="p" variant="body1">
                      <cms.Text id="subtotal" />
                      {' '}
                      {price(invoice.subtotal)}
                    </Typography>
                  </Grid>

                  {!invoice.tax ? null
                    : (
                      <Grid item xs={12} sm={12} style={{ float: 'right', paddingTop: 10 }}>
                        <Typography style={{ float: 'right', fontSize: 13 }} component="p" variant="body1">
                          <cms.Text id="taxPercent" />
                          :
                          {' '}
                          {price(invoice.tax)}
                        </Typography>
                      </Grid>
                    )}
                  <Grid item xs={12} sm={12} style={{ float: 'right', paddingTop: 20 }}>
                    <Typography style={{ float: 'right', fontWeight: 'bolder', textTransform: 'uppercase' }} variant="subtitle1">
                      <cms.Text id="total" />
                      {' '}
                      {price(invoice.total)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ float: 'right', paddingTop: 5 }}>
                    <Typography style={{ float: 'right' }} variant="subtitle2">
                      <cms.Text id="amountPaid" />
                      {' '}
                      {price(invoice.amountPaid)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ float: 'right', paddingTop: 5 }}>
                    <Typography style={{ float: 'right', fontWeight: 'bolder', textTransform: 'uppercase' }} variant="subtitle1">
                      <cms.Text id="amount" />
                      {' '}
                      {price(invoice.balance)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ float: 'right', padding: 16 }}>
                    <Typography style={{ float: 'left', fontSize: 13 }} variant="body1">
                      <cms.Text id="thanksMessage" />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </ContentWrapper>
  );
}
