import { useState } from 'react';
import get from 'lodash/get';
import set from 'lodash/set';
import mergeWith from 'lodash/mergeWith';
import isPlainObject from 'lodash/isPlainObject';


function mergeCustomizer(objValue, srcValue) {
  if (Array.isArray(objValue) || Array.isArray(srcValue)) {
    return srcValue || objValue;
  }
  if (isPlainObject(objValue) || isPlainObject(srcValue)) {
    return srcValue || objValue;
  }
}


/**
 * Takes errors returned from the API and formats them for sending through to the base
 * base Form component
 *
 * @param {Object}
 *
 * @return {Object}
 */
function formatApiErrors(errors) {
  if (!errors) return {};

  return Object.keys(errors).reduce((o, field) => {
    const error = errors[field] || 'invalid';
    return {
      ...o,
      [field]: Array.isArray(error)
        ? error.map(e => e.message).join(', ')
        : error.message || error.type || error
    };
  }, {});
}


export default function useApiForm({
  onSubmit, // (formData: object, formDataPatches: object) => Promise<any>
  fields, // Optional array of field names to include
  validate,
  initialValue,
  onChange,
  onSuccess = () => { },
  extraFieldProps,
  ...rest
}) {
  // props.initialValue is not applied initially to state.data
  // onSubmit will give in first arg the full form data (initialValue included), and in second arg only the patches
  // this can be useful for very large forms (used in settings/general, settings/plugins)
  const [data, setData] = useState({});

  const [{ loading, errMsg, errors }, setState] = useState({
    loading: false,
    errMsg: null,
    errors: {},
  });

  // get full form data, initialValue and form changes deeply merged (we accept names with dot)
  const fullData = mergeWith({}, initialValue, data, mergeCustomizer);
  const formData = !fields ? fullData : Object.fromEntries(Object.entries(fullData).filter(([key]) => fields.includes(key)));

  const updateData = (newData) => setData(data => ({ ...data, ...newData }));

  const getInputProps = (name) => ({
    name,
    value: get(data, name) ?? get(initialValue, name) ?? '', // or get(formData, name) ?? ''
    onChange: handleChange,
  });

  const getFieldProps = (name) => ({
    ...getInputProps(name),
    include: fields ? fields.includes(name) : true,
    error: get(errors, name),
    ...extraFieldProps,
  });


  function handleChange(event) {
    const { name, value: v, multiple } = event.target;
    const value = multiple ? [...event.target.selectedOptions].map(o => o.value) : v;

    setData(data => {
      const newData = set({ ...data }, name, value);

      if (onChange) {
        onChange({
          name,
          value,
          prevValue: get(data, name) ?? get(initialValue, name),
          formData: set({ ...formData }, name, value),
          setFormData: updateData,
        });
      }

      return newData;
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    let errors = {};

    if (validate) {
      const validationErrors = validate(formData);

      if (Object.keys(validationErrors).length) {
        setState({ errors: { ...errors, ...validationErrors } });
        return;
      }
    }

    setState({
      loading: true,
      apiErrors: null
    });

    return onSubmit(formData, data)
      .then(res => onSuccess(res, formData))
      .then(() => {
        setState({
          loading: false
        });
        setData({});
      })
      .catch(err => {
        setState({
          errMsg: err.message,
          errors: formatApiErrors(err.errors),
          loading: false
        });
        // throw err;
      });
  }


  return {
    ...rest,
    fieldProps: getFieldProps,
    inputProps: getInputProps,
    submit: handleSubmit,
    formValue: formData,
    errors,
    errMsg,
    loading,
  };
}
