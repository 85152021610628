import React from 'react';

import { TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import helpers from '../../helpers';
import StateLabel from './state-label';


const { dateShort, price } = helpers.ui;

const useStyles = makeStyles(theme => ({
  row: {
    cursor: 'pointer',
    display: 'table-row',
    userSelect: 'text',

    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },

    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
  },
}));


export default function InvoiceRow({ invoice }) {
  const classes = useStyles();

  return (
    <Link role="row" draggable={false} className={classes.row} to={`invoices/${invoice.sid}`}>
      <TableCell component="span" role="cell" scope="row" style={{ textTransform: 'uppercase' }}>
        {invoice.sid}
      </TableCell>

      <TableCell component="span" role="cell">
        {dateShort(invoice.startDate)}
      </TableCell>

      <TableCell component="span" role="cell">
        {price(invoice.total)}
      </TableCell>

      <TableCell component="span" role="cell">
        <StateLabel state={invoice.state} />
      </TableCell>
    </Link>
  );
}
