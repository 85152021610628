import React from 'react';
import PropTypes from 'prop-types';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepButton from '@material-ui/core/StepButton';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import wurd from 'wurd-react';

class Breadcrumbs extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    site: PropTypes.object,
    unitType: PropTypes.object,
  }

  cms = wurd.block('common.breadcrumbs');

  getActiveStep() {
    const { site, unitType } = this.props;
    if (unitType) return 2;
    if (site) return 1;
    return 0;
  }

  render() {
    const { cms } = this;
    const { site, unitType } = this.props;

    const stepperStyles = {
      background: 'transparent',
      padding: '25px 15px 20px',
    };

    return (
      <Grid container justify="center">
        <Grid item xs={11} lg={9} xl={7}>
          <Stepper style={stepperStyles} activeStep={this.getActiveStep()}>
            <Step>
              {site
                ? <StepButton onClick={this.showMap}>{ site.title }</StepButton>
                : <StepLabel><cms.Text id="selectSite" /></StepLabel>}
            </Step>
            <Step>
              {unitType
                ? <StepButton onClick={this.showSite}>{ unitType.title }</StepButton>
                : <StepLabel><cms.Text id="selectUnit" /></StepLabel>}
            </Step>
            <Step>
              <StepLabel>
                <cms.Text id="finalise" />
              </StepLabel>
            </Step>
          </Stepper>
        </Grid>
      </Grid>
    );
  }

  showMap = () => {
    const { history } = this.props;
    history.push('/');
  }

  showSite = () => {
    const { history, site } = this.props;
    history.push(`/${site.code}`);
  }
}

export default withRouter(Breadcrumbs);
