import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import ModalWindow from '../modal-window';
import { TextField, Typography } from '@material-ui/core';


export default function MyUnitsAccessCodeButton({ cms, accessCodes }) {
  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  return (
    <>
      <Button variant="contained" color="primary" onClick={() => setModalOpen(true)}><cms.Text id="accessCodeBtn" /></Button>
      <ModalWindow
        title={<cms.Text id="title" />}
        modalOpen={modalOpen}
        closeModal={closeModal}
      >
        {Object.entries(accessCodes).map(([key, value]) => {
          return (
            <cms.Object key={key} keys={key} type="div" style={{ marginTop: '1rem' }}>
              <TextField
                label={cms.text(key)}
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                defaultValue={value}
              />
            </cms.Object>
          );
        })}
      </ModalWindow>
    </>
  );
}
