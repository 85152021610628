import React from 'react';
import wurd from 'wurd-react';


import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ProfileIcon from '@material-ui/icons/Person';
import UnitsIcon from '@material-ui/icons/Business';
import Payment from '@material-ui/icons/Payment';
import TermsIcon from '@material-ui/icons/InsertDriveFile';
import CloseIcon from '@material-ui/icons/Clear';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import AttachMoney from '@material-ui/icons/AttachMoney';
import actions from '../../../actions';

import './index.scss';


const cms = wurd.block('common.menu');

const Item = ({ icon, text, ...rest }) => (
  <ListItem button {...rest}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText secondary={text} />
  </ListItem>
);

export default function AppDrawer({ settings, user, isOpen, toggleDrawer }) {
  const { brand } = settings;

  const isLoggedIn = !!user;
  const isLoggedOut = !user;

  return (
    <div>
      <div className={isOpen ? 'sg-drawer open' : 'sg-drawer'}>
        <Toolbar
          style={{
            backgroundColor: brand.color,
            height: '64px',
            cursor: 'pointer',
            justifyContent: 'flex-end',
          }}
          onClick={toggleDrawer}
        >
          {isLoggedIn && (
            <Grid container>
              <Grid item xs={9}>
                <Typography variant="subtitle1" style={{ color: '#fff' }}>
                  <span className="ellipsis">{user.name}</span>
                </Typography>
              </Grid>
            </Grid>
          )}
          <IconButton
            style={{
              color: '#fff',
              backgroundColor: 'rgba(0, 0, 0, .05)',
            }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <List>
          {isLoggedIn && (
            <>
              <Item
                icon={<ProfileIcon />}
                text={<cms.Text id="profile" />}
                component={Link}
                to="/profile"
                onClick={toggleDrawer}
              />
              <Item
                icon={<UnitsIcon />}
                text={<cms.Text id="units" />}
                component={Link}
                to="/my-units"
                onClick={toggleDrawer}
              />
              <Item
                icon={<AttachMoney />}
                text={<cms.Text id="invoices" />}
                component={Link}
                to="/invoices"
                onClick={toggleDrawer}
              />
              <Item
                icon={<Payment />}
                text={<cms.Text id="billing" />}
                component={Link}
                to="/billing"
                onClick={toggleDrawer}
                divider
              />
            </>
          )}
          {isLoggedOut && (
            <>
              <Item
                icon={<UnitsIcon />}
                text={<cms.Text id="book" />}
                component={Link}
                to="/"
                onClick={toggleDrawer}
                divider
              />
              <Item
                icon={<ProfileIcon />}
                text={<cms.Text id="login" />}
                component={Link}
                to="/login"
                onClick={toggleDrawer}
              />
            </>
          )}
          <Item
            icon={<TermsIcon />}
            text={<cms.Text id="terms" />}
            component="a"
            href={settings.termsUrl}
            target="_blank"
            rel="noopener noreferrer"
          />
          {settings.privacyUrl && (
            <Item
              icon={<TermsIcon />}
              text={<cms.Text id="privacy" />}
              component="a"
              href={settings.privacyUrl}
              target="_blank"
            />
          )}
          {isLoggedIn && (
            <Item
              icon={<LogoutIcon />}
              text={<cms.Text id="logout" />}
              onClick={actions.user.logout}
            />
          )}
        </List>
        <Grid container justify="center" spacing={2}>
          <Grid item>
            <a
              href="https://storeganise.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography color="secondary" variant="caption">
                <cms.Text id="storeganiseLink" />
              </Typography>
            </a>
          </Grid>
        </Grid>
      </div>
      <div onClick={toggleDrawer} className="sg-overlay" />
    </div>
  );
}
