import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import NoneIcon from '@material-ui/icons/NotInterested';
import PageTitle from '../page-title';
import ContentWrapper from '../content-wrapper';
import './index.scss';

class NoResults extends React.Component {
  render() {
    const { cms } = this.props;

    const buttonStyles = { marginTop: '12px' }; // TO DO: Find a way to move me to the SCSS file.

    return (
      <ContentWrapper>
        <div className="sg-noresults">
          <NoneIcon color="secondary" className="sg-noresults-icon" />
          <PageTitle color="secondary"><cms.Text id="noResults" /></PageTitle>
          <Button style={buttonStyles} variant="contained" color="primary" component={Link} to="/"><cms.Text id="bookUnit" /></Button>
        </div>
      </ContentWrapper>
    );
  }
}

export default NoResults;
