import loadImage from 'blueimp-load-image';
import store from '../../store';


function resizeImage(file) {
  return new Promise((resolve, reject) => {
    //Load and resize images before sending
    var loadImageOptions = {
      canvas: true,
      maxWidth: 1200,
      maxHeight: 1200
    };

    loadImage(file, canvas => {
      //if (canvas.type == 'error') { return alert('Error loading image'); }
      if (canvas.type === 'error') {
        return reject('Error uploading image');
      }

      //NOTE: Requires the canvas.toBlob polyfill (https://github.com/blueimp/JavaScript-Canvas-to-Blob)
      canvas.toBlob(resolve, file.type);
    }, loadImageOptions);
  });
}

function toBase64(file) {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = () => resolve(window.btoa(reader.result));
    reader.onerror = reject;
    reader.readAsBinaryString(file);
  })
}

export async function processData(customFields) {
  const { userCustomFields = [] } = store.get('settings');

  const processedEntries = await Promise.all(
    Object.entries(customFields).map(async ([k, v]) => {
      const cfSetting = userCustomFields.find(o => o.code === k);

      if (v?.url?.startsWith('blob:') && v.file) { // blob file needing to be uploaded
        const blob = v.file.type.startsWith('image/') ? await resizeImage(v.file) : v.file;
        const data = await toBase64(blob);
        return [k, { name: v.file.name, data }];
      }

      if (v === '') {
        return [k, null];
      }

      if (cfSetting?.type === 'number' || cfSetting?.type === 'integer') {
        return [k, +v];
      }

      return [k, v];
    })
  );
  return Object.fromEntries(processedEntries);
}

export default processData;
