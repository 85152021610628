import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Paper, Typography, Grid } from '@material-ui/core';
import store from '../../../store';
import actions from '../../../actions';
import SignupForm from './signup-form';
import LoginForm from './login-form';
import LoggedInForm from './logged-in-form';


const paperStyles = { padding: '28px 24px 24px 24px' };


class UnitDetailAccountStep extends React.Component {
  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
    cms: PropTypes.object.isRequired,
    user: PropTypes.object,
  };

  state = {
    formType: 'signup', // 'signup'|'login'|'moveDate'
    startDate: null,
  }


  componentDidMount() {
    if (this.props.user) { // switch to moveDate only form
      this.setState({ formType: 'moveDate' });
    }
  }

  componentDidUpdate(prevProps) {
    const { formType } = this.state;
    if (this.props.user !== prevProps.user) {
      this.setState({ formType: this.props.user ? 'moveDate' : 'signup' });
    }
  }

  render() {
    const { formType } = this.state;
    return this[`renderFormType_${formType}`]();
  }

  renderFormType_signup() {
    const cms = this.props.cms.block('signup');

    const initialFormValue = {
      // startDate: moment().format('YYYY-MM-DD')
    };

    return (
      <Paper style={paperStyles}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" gutterBottom>
              <cms.Text id="title" />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" color="secondary" style={{ textAlign: 'right', float: 'right' }} onClick={this.showLogin}>
              <cms.Text id="haveAccount" />
              {' '}
              <u><cms.Text id="loginLink" /></u>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SignupForm
              initialValue={initialFormValue}
              onSubmit={actions.user.signup}
              onSuccess={this.onSuccess}
              cms={cms}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }

  renderFormType_login() {
    const cms = this.props.cms.block('login');

    const initialFormValue = {
      // startDate: moment().format('YYYY-MM-DD')
    };

    return (
      <Paper style={paperStyles}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" gutterBottom>
              <cms.Text id="title" />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" onClick={this.showSignup} style={{ textAlign: 'right', float: 'right' }}>
              <cms.Text id="noAccount" />
              {' '}
              <u><cms.Text id="signupLink" /></u>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <LoginForm
              initialValue={initialFormValue}
              onSubmit={actions.user.login}
              onSuccess={this.onSuccess}
              cms={cms}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }

  renderFormType_moveDate() {
    const cms = this.props.cms.block('moveDate');
    const { user } = store.get();

    const initialFormValue = {
      // startDate: moment().format('YYYY-MM-DD')
    };

    return (
      <Paper style={paperStyles}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" gutterBottom>
              <cms.Text id="title" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <LoggedInForm
              initialValue={initialFormValue}
              onSubmit={async () => user}
              onSuccess={this.onSuccess}
              cms={cms}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }

  showLogin = () => {
    this.setState({ formType: 'login' });
  };

  showSignup = () => {
    this.setState({ formType: 'signup' });
  };

  onSuccess = (user, formData) => {
    this.props.onSuccess({
      user,
      startDate: formData.startDate,
    });
  };
}

export default UnitDetailAccountStep;
