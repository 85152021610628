import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Typography, Grid, Button, FormControlLabel, Checkbox } from '@material-ui/core';

import { useApiForm } from '../../../hooks';
import TextField from '../../form/text-field.js';
import DateField from '../../form/date-field.js';
import store from '../../../store';
import CustomField from '../../../plugins/custom-fields/field';
import helpers from '../../../helpers';


export default function UnitDetailSignupForm(props) {
  const { fieldProps, inputProps, submit, errMsg, errors, loading, ...rest } = useApiForm(props);
  const { cms } = props;
  const { termsUrl, requireAgreeTerms, userCustomFields = [] } = store.get('settings');

  return (
    <form onSubmit={submit}>
      <Grid container spacing={2} justify="space-between">
        <Grid item xs={12} sm={6}>
          <TextField
            {...fieldProps('firstName')}
            label={<cms.Text id="firstName.label" />}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fieldProps('lastName')}
            label={<cms.Text id="lastName.label" />}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fieldProps('email')}
            label={<cms.Text id="email.label" />}
            type="email"
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fieldProps('password')}
            label={<cms.Text id="password.label" />}
            type="password"
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...fieldProps('phone')}
            label={<cms.Text id="phone.label" />}
            type="tel"
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateField
            {...fieldProps('startDate')}
            label={<cms.Text id="startDate.label" />}
            required
            maxDate={moment().add(1, 'month')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...fieldProps('address')}
            multiline
            label={<cms.Text id="address.label" />}
            required
          />
        </Grid>
        {userCustomFields
          .filter(({ ownerAcl }) => ownerAcl?.includes('create')) // not showing read-only fields in a signup page, maybe we should, gotta ask @miles
          .map(({ code, title, ownerAcl, ...rest }) => (
            <Grid key={code} item xs={12} sm={6}>
              <CustomField
                {...fieldProps(`customFields.${code}`)}
                label={helpers.ui.getLangText(title)}
                {...rest}
              />
            </Grid>
          ))}
        <Grid item xs={12}>
          {requireAgreeTerms
            ? (
              <FormControlLabel
                control={
                  <Checkbox
                    {...inputProps('agreeTerms')}
                    required
                    color="primary"
                  />
                }
                label={<cms.Markdown id="agreeTerms" vars={{ termsUrl }} style={{ fontWeight: 'normal', marginLeft: 15 }} />}
              />
            ) : (
              <cms.Markdown id="agreeTerms" vars={{ termsUrl }} style={{ fontWeight: 'normal' }} />
            )}
        </Grid>
        <Grid item xs={12}>
          {errMsg && (
            <Typography color="error">
              {errMsg}
              {errors &&
                <ul>
                  {Object.entries(errors).map(([key, msg]) => (
                    <li key={key}>{key}: {msg}</li>
                  ))}
                </ul>
              }
            </Typography>
          )}

          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ float: 'right' }}
            disabled={loading}
          >
            <cms.Object keys="submit,submitting">
              {loading ? cms.text('submitting') : cms.text('submit')}
            </cms.Object>
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

UnitDetailSignupForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
