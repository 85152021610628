import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';


const LinkButton = ({ to, children, ...rest }) => (
  <Button
    variant="contained"
    color="primary"
    component={Link}
    to={to}
    {...rest}
  >
    {children}
  </Button>
);


LinkButton.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};


export default LinkButton;
