import React from 'react';
// import wurd from 'wurd-react';

import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import NoneIcon from '@material-ui/icons/NotInterested';
import ContentWrapper from './content-wrapper';
import PageTitle from './page-title';


const NotFound = () => (
  <ContentWrapper>
    <div className="sg-noresults">
      <NoneIcon color="secondary" className="sg-noresults-icon" />
      <PageTitle color="secondary">Not found</PageTitle>

      <Button
        style={{ marginTop: 12 }}
        variant="contained"
        color="primary"
        component={Link}
        to="/"
      >
          Find a unit
      </Button>
    </div>
  </ContentWrapper>
);


export default NotFound;
