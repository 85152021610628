/**
 * 
 * @param {'stripe'|'braintree'|'gmopg'} billing 
 */
export default function getBillingModule(billing) {
  switch (billing) {
    case 'stripe':
      return import('./stripe');

    case 'gmopg':
      return import('./gmopg');

    case 'braintree':
      return import('./braintree');

    default:
      throw new Error(`billing ${billing} unsupported`);
  }
}
