import React, { useEffect, useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Hidden,
  Grid,
} from '@material-ui/core';
import wurd from 'wurd-react';

import InvoiceRow from './invoice-row';
import InvoicesMobile from './invoices-mobile';
import actions from '../../actions';
import Spinner from '../spinner';
import NoResults from '../no-results';
import PageTitle from '../page-title';
import ContentWrapper from '../content-wrapper';
import store from '../../store';


const cms = wurd.block('invoices');

const paperStyles = { padding: '20px' };

export default function Invoices() {
  const [invoices, setInvoices] = useState(null);
  const { settings } = store.get();

  useEffect(() => {
    actions.invoices.fetchInvoices().then(setInvoices);
  }, []); // only fetch Invoices initially, no pagination (yet?)

  if (!invoices) return <Spinner />;

  return invoices.length === 0 ? <NoResults cms={cms} /> : (
    <ContentWrapper align="top">
      <PageTitle><cms.Text id="title" /></PageTitle>
      <Grid item xs={12} style={{ padding: 10 }}>
        <Hidden xsDown>
          <Paper style={paperStyles}>
            <Table component="div" role="table">
              <TableHead component="div" role="rowgroup">
                <TableRow component="div" role="row">
                  <TableCell component="span" role="columnheader"><cms.Text id="number" /></TableCell>
                  <TableCell component="span" role="columnheader"><cms.Text id="date" /></TableCell>
                  <TableCell component="span" role="columnheader"><cms.Text id="amount" /></TableCell>
                  <TableCell component="span" role="columnheader"><cms.Text id="status" /></TableCell>
                </TableRow>
              </TableHead>
              <TableBody component="nav" role="rowgroup">
                {invoices.map((invoice) => (invoice.state === 'draft' ? null : (
                  <InvoiceRow
                    key={invoice.sid}
                    invoice={invoice}
                    settings={settings}
                    cms={cms}
                  />
                )))}
              </TableBody>
            </Table>
          </Paper>
        </Hidden>
        <Hidden smUp>
          {invoices.map((invoice) => (invoice.state === 'draft' ? null : (
            <Paper key={invoice.sid}>
              <InvoicesMobile settings={settings} cms={cms} invoice={invoice} />
            </Paper>
          )))}
        </Hidden>
      </Grid>
    </ContentWrapper>
  );
}
