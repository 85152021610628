import moment from 'moment';

export default {
  init,
  get,
};


let _currentLang;


/**
 * Detects and sets the current language
 */
async function init(availableLanguages = ['en']) {
  const detectedLang = detectQueryStringLanguage() // First get from query string
    || window.localStorage.getItem('lang') // Then localStorage
    || detectBrowserLanguage(); // Fall back to browser setting

  const _baseLang = detectedLang.split('-', 1)[0];
  const baseLang = { nb: 'no' }[_baseLang] || _baseLang; // convert 'nb' to 'no' as we use 'no' in our availableLanguages settings for Norsk

  // Ensure in list of supported languages or check if there is content for a non-region specific language, fallback to default
  _currentLang = [detectedLang, baseLang].find(l => availableLanguages.includes(l)) || availableLanguages[0];

  // Update moment locale for calendars etc.
  // This is to make webpack not bundle all moment locales, we could also do import(`moment/locale/${lang}`) and use moment-locales-webpack-plugin but more complex to customize build with react-scripts
  switch (_currentLang) {
    case 'da': return import('moment/locale/da');
    case 'de': return import('moment/locale/de');
    case 'es': return import('moment/locale/es');
    case 'fr': return import('moment/locale/fr');
    case 'ja': return import('moment/locale/ja');
    case 'ko': return import('moment/locale/ko');
    case 'nl': return import('moment/locale/nl');
    case 'pt': return import('moment/locale/pt');
    case 'zh': return import('moment/locale/zh-cn'); // There is no 'base' zh in moment
    case 'no': return import('moment/locale/nb'); // There is no base 'no'; Nynorsk is mostly used in Western Norway as a written language (by roughly 10 % of the people, amounting to about half a million), Bokmål is dominant in the rest of the country, and is used in writing by close to 90 %.
    case 'sv': return import('moment/locale/sv');
  }

  moment.locale(baseLang);
}


/**
 * Loads content from the cache
 *
 * @return {Object}
 */
function get() {
  if (!_currentLang) {
    throw new Error('You must call init(availableLanguages) before trying to get the current language');
  }

  return _currentLang;
}


/**
 * @return {String}
 */
function detectQueryStringLanguage() {
  if (typeof window === 'undefined') return;

  const query = new URLSearchParams(window.location.search);
  return query.get('lang');
}

/**
 * @return {String}
 */
function detectBrowserLanguage() {
  const userLngChoices = [];

  // Detect from browser settings
  if (typeof navigator !== 'undefined') {
    if (navigator.languages) { // chrome only; not an array, so can't use .push.apply instead of iterating
      for (let i = 0; i < navigator.languages.length; i++) {
        userLngChoices.push(navigator.languages[i]);
      }
    }

    if (navigator.userLanguage) {
      userLngChoices.push(navigator.userLanguage);
    }

    if (navigator.language) {
      userLngChoices.push(navigator.language);
    }
  }

  return userLngChoices[0];
}
