import React from 'react';

import { WurdText } from 'wurd-react';
import store from '../../store';
import actions from '../../actions';


import './index.css';


const LanguageSwitcher = () => {
  const { settings, lang: currentLang } = store.get();
  const availableLangs = settings.languages;

  // Only display if there are multiple languages options
  if (!availableLangs || availableLangs.length < 2) return null;

  const newLang = availableLangs.find((lang) => lang !== currentLang);

  return (
    <button onClick={() => actions.user.setLanguage(newLang)} className="sg-language-switcher">
      <WurdText id={`common.language.${newLang}`} />
    </button>
  );
};

export default LanguageSwitcher;
