import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import actions from '../../actions';

import { useApiForm } from '../../hooks';
import SubmitButton from '../form/submit-button';
import TextField from '../form/text-field';


export default function ForgotPasswordForm({ cms, onSuccess }) {
  const { fieldProps, submit, errMsg, loading, ...rest } = useApiForm({
    onSubmit: (data) => actions.user.sendForgotPasswordEmail(data),
    onSuccess
  });

  return (
    <form onSubmit={submit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            {...fieldProps('email')}
            label={<cms.Text id="email" />}
            type="email"
            required
          />
        </Grid>
        <Grid item xs={12}>
          {errMsg
            && <Typography color="error">{errMsg}</Typography>}
          <SubmitButton
            cms={cms}
            loading={loading}
          />
        </Grid>
      </Grid>
    </form>
  );
}

ForgotPasswordForm.propTypes = {
  cms: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
