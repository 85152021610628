import { hasBillingMethod } from './user';
import language from '../utils/language';


export function createStepManager(allSteps) {
  const getNext = function (step) {
    return allSteps[allSteps.indexOf(step) + 1];
  };

  return function getNextStep({ settings, user, site, step }) {
    let nextStep = step ? getNext(step) : allSteps[0];
    // account step should never be skipped, see account/index that still render moveIn date field if user is signed in
    // if (nextStep === 'account') {
    //   if (user) {
    //     nextStep = getNext(nextStep);
    //   }
    // }

    if (nextStep === 'products') {
      if (!site.products?.length) {
        nextStep = getNext(nextStep);
      }
    }

    if (nextStep === 'billing') {
      if (hasBillingMethod(settings, user)) {
        nextStep = getNext(nextStep);
      }
    }

    if (nextStep === 'agreement') {
      if (!site.enableMoveInAgreement) {
        nextStep = getNext(nextStep);
      }
    }

    return nextStep;
  };
}
