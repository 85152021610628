import React, { useState } from 'react';
import MuiAppBar from './app-bar';
import AppDrawer from './app-drawer';


const wrapperStyles = {
  zIndex: 99997,
  position: 'relative',
};

export default function AppHeader({ settings, user }) {
  const [isOpen, setOpen] = useState(false);

  const toggleDrawer = () => setOpen(open => !open);

  return (
    <div style={wrapperStyles}>
      <MuiAppBar
        settings={settings}
        toggleDrawer={toggleDrawer}
      />
      <AppDrawer
        settings={settings}
        user={user}
        isOpen={isOpen}
        toggleDrawer={toggleDrawer}
      />
    </div>
  );
}
