import api from '../api';
import store from '../store';
import language from '../utils/language';
import errHandler from './_errhandler';
import { processData as processCustomFieldsData } from '../plugins/custom-fields/actions';
import btoa from '../utils/btoa';


const includeOpts = 'settings,billingMethod,units,customFields';

export async function signup(data) {
  // Set the user language to what is currently active
  data.language = data.language || language.get();

  if (data.customFields) {
    data.customFields = await processCustomFieldsData(data.customFields);
  }

  return api.post('/v1/users', data)
    .then((res) => login(data))
    .catch(errHandler);
}


export function login(data) {
  const { email, password } = data;

  const apiConfig = {
    headers: {
      Authorization: `Basic ${btoa(`${email}:${password}`)}`,
      'X-Requested-With': 'XMLHttpRequest', // Prevents Safari displaying BasicAuth popup)
    },
  };

  return api.post(`/v1/auth/token?include=${includeOpts}`, {}, apiConfig)
    .then((res) => {
      const { accessToken, user } = res.data;

      localStorage.setItem('accessToken', accessToken);

      store.set({ user });

      return user;
    })
    .catch(errHandler);
}


export function logout() {
  localStorage.removeItem('accessToken');

  store.set({ user: undefined });
}


/**
 * Gets latest user info and stores it
 *
 * @return {Promise}
 * @resolves {User}
 */
export function fetch() {
  return api.get(`/v1/user?include=${includeOpts}`)
    .then((res) => {
      const user = res.data;

      store.set({ user });

      return user;
    });
}


/**
 * Updates a user's profile
 *
 * @param {Object} data
 *
 * @return {Promise}
 * @resolves {User}
 */
export async function update(data) {
  if (data.customFields) {
    data.customFields = await processCustomFieldsData(data.customFields);
  }

  return api.put(`/v1/user?include=${includeOpts}`, data)
    .then((res) => {
      const user = res.data;

      store.set({ user });

      return user;
    });
}


/**
 * Updates the user's preferred language
 *
 * @param {String} code   e.g. 'en'
 */
export async function setLanguage(code) {
  // Save for next time
  localStorage.setItem('lang', code);

  // Save to user if logged in
  const { user } = store.get();

  if (user) {
    await api.put(`/v1/users/${user.id}`, { language: code });
  }

  // Reload
  window.location.reload();
}


/**
 * @param {Object} data
 * @param {String} data.email
 */
export function sendForgotPasswordEmail(data) {
  return api.post('/v1/auth/forgot-password', data);
}


/**
 * @param {Object} data
 * @param {String} data.email
 * @param {String} data.password
 * @param {String} data.token
 */
export function resetPassword(data) {
  return api.post('/v1/auth/reset-password', data);
}
