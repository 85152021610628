import * as settings from './settings';
import * as user from './user';
import * as orders from './orders';
import * as invoices from './invoices';

export default {
  settings,
  user,
  orders,
  invoices,
};
