import React from 'react';

import Typography from '@material-ui/core/Typography';

import Hero from '../hero';

const UnitHero = (props) => {
  const { site, unitType } = props;

  const titleStyles = { fontWeight: 300 };

  return (
    <Hero
      title={unitType.title}
      image={unitType.image}
    >
      <Typography variant="h6" color="secondary" style={titleStyles} gutterBottom>
        <span className="ellipsis">{ site.title }</span>
      </Typography>
      <Typography variant="h6" gutterBottom>
        <span className="ellipsis">{ unitType.title }</span>
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        <span className="ellipsis">{ unitType.subtitle }</span>
      </Typography>
    </Hero>
  );
};

export default UnitHero;
