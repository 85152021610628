import store from '../store';


/**
 * Get the sites that have lat & lng defined (can be shown on the map)
 *
 * @return {Object[]} sites
 */
export function listMapped() {
  const { sites } = store.get('settings');

  return sites.filter((site) => site.lat && site.lng);
}
