import api from '../api';
import config from '../config';
import errHandler from './_errhandler';

const baseUrl = '/v1/units/orders';

export const create = function (data) {
  return api.post(baseUrl, data)
    .then((res) => res.data)
    .catch(errHandler);
};

export const previewAgreement = function (data) {
  return api.post(`${baseUrl}/esign-preview`, data)
    .then(r => r.data)
    .catch(errHandler);
};

export const fetch = function (id) {
  return api.get(`${baseUrl}/${id}`)
    .then((res) => res.data)
    .catch(errHandler);
};
