import React from 'react';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import './index.scss';

export default function Tooltips({ tags }) {
  return (
    <Grid container className="sg-tooltips" component="ul">
      {tags.map((tag) => (
        <Grid item key={tag.id} component="li">
          <Tooltip enterDelay={100} leaveDelay={100} placement="bottom" title={tag.title}>
            <IconButton className="sg-tooltip">
              <i className={`fa fa-fw ${tag.icon}`} />
            </IconButton>
          </Tooltip>
        </Grid>
      ))}
    </Grid>
  );
}
